// aethericai_front/src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';
import { ME_QUERY } from '../graphql/User/user_queries';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [showLoginModal, setShowLoginModal] = useState(false);
  const client = useApolloClient();

  const logoutTimerIdRef = useRef(null);

  const login = (token, userData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setAuthToken(token);
    setUser(userData);
    setShowLoginModal(false);
    scheduleAutoLogout(token);
  };

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setAuthToken(null);
    setUser(null);

    // Annulation du timer
    if (logoutTimerIdRef.current) {
      clearTimeout(logoutTimerIdRef.current);
      logoutTimerIdRef.current = null;
    }

    client.clearStore();
    setShowLoginModal(true);
  }, [client]);


    /**
   * 1. Décode le token, récupère "exp" => renvoie un timestamp en millisecondes
   */
  const getTokenExpirationTime = (token) => {
    try {
      const decoded = jwtDecode(token); // decode le payload du JWT
      if (decoded && decoded.exp) {
        // exp est en secondes => on convertit en ms
        return decoded.exp * 1000;
      }
      return null;
    } catch (err) {
      console.error('Error decoding token:', err);
      return null;
    }
  };

    /**
   * 2. Programme un setTimeout pour déconnecter l'utilisateur
   *    x millisecondes avant la date d'expiration du token
   */
  const scheduleAutoLogout = useCallback(
    (token) => {
      const expirationTime = getTokenExpirationTime(token);
      if (!expirationTime) return;

      const currentTime = Date.now();
      const timeRemaining = expirationTime - currentTime;
      console.log('Time until token expiration (ms) =', timeRemaining);

      // Exemple : on déconnecte 30 secondes avant l'expiration réelle
      const logoutThreshold = timeRemaining - 30 * 1000;
      if (logoutThreshold <= 0) {
        // Le token est déjà sur le point d'expirer (ou expiré)
        logout();
        return;
      }

      // On annule tout timer précédent
      if (logoutTimerIdRef.current) {
        clearTimeout(logoutTimerIdRef.current);
      }

      // On programme la déconnexion
      const timerId = setTimeout(() => {
        console.log('Auto logout triggered: token expired or about to expire.');
        logout();
      }, logoutThreshold);

      // On stocke l'ID dans la ref
      logoutTimerIdRef.current = timerId;
    },
    [logout]
  );

    /**
   * Au chargement, si on a un token valid dans localStorage,
   * on relance le timer d'auto-logout.
   * Sinon, on fait un logout() s'il est expiré.
   */
  /**
   * Au chargement, si on a un token valid dans localStorage,
   * on relance le timer d'auto-logout.
   * Sinon, on fait un logout() s'il est expiré.
   */
  useEffect(() => {
    if (authToken) {
      // on vérifie rapidement s'il n'est pas déjà expiré
      const expirationTime = getTokenExpirationTime(authToken);
      if (expirationTime && expirationTime > Date.now()) {
        // encore valide => on programme la déconnexion auto
        scheduleAutoLogout(authToken);
      } else {
        // déjà expiré
        logout();
      }
    }
  }, [authToken, scheduleAutoLogout, logout]);

  /**
   * Si on a un authToken mais pas encore de user,
   * on tente la requête me() pour remplir les infos user.
   */
  useEffect(() => {
    if (authToken && !user) {
      const fetchUserData = async () => {
        try {
          const { data } = await client.query({ query: ME_QUERY });
          if (data && data.me) {
            setUser(data.me);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout();
        }
      };
      fetchUserData();
    }
  }, [authToken, user, client, logout]);

  /**
   * Écoute l'événement 'tokenExpired' dispatché par apolloClient
   */
  useEffect(() => {
    const handleUnauthorized = () => {
      logout(); // Clear token and user data
      setShowLoginModal(true); // Show the login modal
    };

    window.addEventListener('tokenExpired', handleUnauthorized);
    return () => {
      window.removeEventListener('tokenExpired', handleUnauthorized);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout, showLoginModal, setShowLoginModal }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
