import React, { useState, useEffect } from "react";

const UpdateNodeForm = ({ currentNode, onCancel, onSubmitUpdate }) => {
  const [nodeName, setNodeName] = useState(currentNode?.name || "");
  const [nodeDescription, setNodeDescription] = useState(currentNode?.description || "");
  const [nodeTags, setNodeTags] = useState(currentNode?.tags?.join(", ") || "");
  const [nodePrivacy, setNodePrivacy] = useState(currentNode?.privacy_status || "PRIVATE");
  const [nodeStatus, setNodeStatus] = useState(currentNode?.status || "ACTIVE");

  useEffect(() => {
    setNodeName(currentNode?.name || "");
    setNodeDescription(currentNode?.description || "");
    setNodeTags((currentNode?.tags || []).join(", "));
    setNodePrivacy(currentNode?.privacy_status || "PRIVATE");
    setNodeStatus(currentNode?.status || "ACTIVE");
  }, [currentNode]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!currentNode?.id) {
      console.error("No currentNode or ID, cannot update.");
      return;
    }

    const tagsArray = nodeTags
      .split(",")
      .map((t) => t.trim())
      .filter(Boolean);

    const nodeInput = {
      name: nodeName,
      description: nodeDescription,
      tags: tagsArray,
      privacy_status: nodePrivacy,
      status: nodeStatus,
      properties: {},
    };

    try {
      await onSubmitUpdate(currentNode.id, nodeInput);
      if (onCancel) onCancel();
    } catch (error) {
      console.error("Error updating node:", error);
    }
  };

  return (
    <form onSubmit={handleUpdateSubmit} className="bg-gray-800 p-2 rounded space-y-2 text-sm">
      {/* Name row */}
      <div className="flex items-center gap-2">
        <label className="w-16 text-gray-300 font-medium">Name</label>
        <input
          type="text"
          value={nodeName}
          onChange={(e) => setNodeName(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        />
      </div>

      {/* Description row */}
      <div className="flex items-start gap-2">
        <label className="w-16 text-gray-300 font-medium">Desc</label>
        <textarea
          value={nodeDescription}
          onChange={(e) => setNodeDescription(e.target.value)}
          rows={2}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded resize-none"
        />
      </div>

      {/* Tags row */}
      <div className="flex items-center gap-2">
        <label className="w-16 text-gray-300 font-medium">Tags</label>
        <input
          type="text"
          placeholder="comma-separated"
          value={nodeTags}
          onChange={(e) => setNodeTags(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        />
      </div>

      {/* Privacy */}
      <div className="flex items-center gap-2">
        <label className="w-16 text-gray-300 font-medium">Privacy</label>
        <select
          value={nodePrivacy}
          onChange={(e) => setNodePrivacy(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="PUBLIC">PUBLIC</option>
          <option value="PRIVATE">PRIVATE</option>
          <option value="RESTRICTED">RESTRICTED</option>
        </select>
      </div>

      {/* Status */}
      <div className="flex items-center gap-2">
        <label className="w-16 text-gray-300 font-medium">Status</label>
        <select
          value={nodeStatus}
          onChange={(e) => setNodeStatus(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="PENDING_VALIDATION">PENDING_VALIDATION</option>
          <option value="DEACTIVATED">DEACTIVATED</option>
        </select>
      </div>

      {/* Buttons */}
      <div className="mt-2 flex justify-end gap-2">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-600 hover:bg-gray-500 text-white px-2 py-1 rounded"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-500 text-white px-2 py-1 rounded"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default UpdateNodeForm;