// src/components/AethericAI/DevGraphNavigator/ActionPanel.js

import React, { useContext, useState } from "react";
import InitialRootContext from "../../../contexts/InitialRootContext";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid";
import NodeCreationForm from "./NodeCreationForm";
import UpdateNodeForm from "./UpdateNodeForm";

export const ActionPanel = ({ 
  selectedNodes,
  setSelectedNodes, 
  onSetRoot, 
  rootNode, 
  panelOpenNode,
  setPanelOpenNode, 
  actionPanelOpen,
  setActionPanelOpen,
  handleCreateNode,
  handleCreateRelationship,
  handleUpdateNode
}) => {
  const initialRootNode = useContext(InitialRootContext);
  const [isNodeCreationOpen, setIsNodeCreationOpen] = useState(false);
  const [isNodeUpdateOpen, setIsNodeUpdateOpen] = useState(false);

    // 1) Define a new combined function
  const handleNodeAndRelationship = async (nodeInput) => {
     // 1) Create the node in DB
     const createdNodeResponse = await handleCreateNode(nodeInput);
  
     // 2) If there's an origin node (panelOpenNode),
     //    create a "RELATED_TO" relationship from origin -> new node
     if (panelOpenNode?.id) {
       const newNode = createdNodeResponse?.createNode?.nodes?.[0];
       if (newNode?.id) {
         // Build your relationship
         const relationshipInput = {
           type: "RELATED_TO",
           source: panelOpenNode.id,
           target: newNode.id,
           properties: {}, // any extras?
         };
         await handleCreateRelationship(relationshipInput);
       }
     }
  
     // Return if needed
     return createdNodeResponse;
   };

  const handleSelectNode = (node) => {
    setPanelOpenNode(node);
  };

  const handleRemoveNode = (node) => {
    setSelectedNodes((currentSelected) =>
      currentSelected.filter((n) => n.id !== node.id)
    );
    if (selectedNodes.length === 1) { 
      setPanelOpenNode(null);
      setActionPanelOpen(false);
    }
  };

  const handleClose = () => {
    setActionPanelOpen(false);
    setPanelOpenNode(null);
  };

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 }
  };

  const handleNodeCreated = () => {
    setIsNodeCreationOpen(false);
  };

  if (!actionPanelOpen) {
    return null;
  } else {
    return (
      <motion.div
        className="
          action-panel 
          bg-black text-white p-3 border border-gray-300 rounded-md z-50 
          w-full sm:w-64 md:w-72 lg:w-80
          shadow-lg
          fixed 
          bottom-0 left-0 right-0 sm:bottom-10 sm:right-10 sm:left-auto
          cursor-default select-none
          max-h-1/2 sm:max-h-full
          overflow-y-auto
        "
        drag
        dragHandle=".handle"
        dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }} 
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        role="dialog"
        aria-modal="true"
        aria-labelledby="action-panel-title"
      >
        <div className="flex justify-between items-center mb-2 handle cursor-move">
          <p className="text-md font-medium">
            <button 
              onClick={() => onSetRoot(initialRootNode)} 
              className="hover:underline focus:outline-none"
            >
              {initialRootNode.name}
            </button>
          </p>
          <button 
            onClick={handleClose} 
            className="text-gray-400 hover:text-white focus:outline-none"
            aria-label="Close Action Panel"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="mb-2">
          <button 
            onClick={() => onSetRoot(panelOpenNode)} 
            className="w-full text-left mb-1 bg-gray-800 hover:bg-gray-700 p-1 rounded text-sm"
            disabled={!actionPanelOpen} 
          >
            {actionPanelOpen && panelOpenNode ? panelOpenNode.name : "None Opened"}
          </button>
          <button
            className="w-full bg-green-700 hover:bg-green-600 p-1 rounded text-sm"
            onClick={() => setIsNodeCreationOpen(!isNodeCreationOpen)}
          >
            {isNodeCreationOpen ? "Cancel New Node" : "Create Node"}
          </button>
          <button
            className="w-full bg-blue-700 hover:bg-blue-600 p-1 rounded text-sm mt-1"
            onClick={() => setIsNodeUpdateOpen(!isNodeUpdateOpen)}
            disabled={!panelOpenNode} 
          >
            {isNodeUpdateOpen ? "Cancel Update Node" : "Update Node"}
          </button>
        </div>

        {isNodeCreationOpen && (
          <div className="mb-2">
            <NodeCreationForm
              originNode={panelOpenNode}
              onCancel={() => setIsNodeCreationOpen(false)}
              onCreate={handleNodeCreated}
              defaultLabel="CONCEPT"
              onSubmitNode={handleNodeAndRelationship}
            />
          </div>
        )}


        {isNodeUpdateOpen && (
          <div className="mb-2">
            <UpdateNodeForm
              currentNode={panelOpenNode}         // pass the open node
              onCancel={() => setIsNodeUpdateOpen(false)}
              onSubmitUpdate={handleUpdateNode}   // or whatever function you use
            />
          </div>
        )}

        <hr className="border-gray-700 mb-2" />

        <div className="selected-nodes-section mb-2">
          <h3 id="action-panel-title" className="text-sm font-semibold mb-1">Selected Nodes:</h3>
          {selectedNodes.length === 0 ? (
            <p className="text-xs text-gray-400">No nodes selected.</p>
          ) : (
            <ul className="space-y-1 max-h-32 overflow-y-auto">
              {selectedNodes.map((node) => (
                <li
                  key={node.id}
                  className="p-1 rounded cursor-pointer hover:bg-gray-700 flex justify-between items-center text-xs"
                  onClick={() => handleSelectNode(node)}
                  onDoubleClick={() => handleRemoveNode(node)}
                  title="Click to open | Double-click to remove"
                >
                  <span>{node.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </motion.div>
    );
  }
};