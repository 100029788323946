import React, { useState } from 'react';

const NodeCreationForm = ({
  originNode,
  onCancel,
  onCreate,
  onSubmitNode,
  handleCreateRelationship,
  defaultLabel = "CONCEPT"
}) => {
  const [newNodeLabel, setNewNodeLabel] = useState(defaultLabel);
  const [newNodeName, setNewNodeName] = useState("");
  const [newNodeDescription, setNewNodeDescription] = useState("");
  const [newNodePrivacy, setNewNodePrivacy] = useState("PRIVATE");
  const [newNodeTags, setNewNodeTags] = useState("");
  const [newNodeStatus, setNewNodeStatus] = useState("PENDING_VALIDATION");

  const handleCreateNodeSubmit = async (e) => {
    e.preventDefault();

    // Convert tags to array
    const tagsArray = newNodeTags
      .split(",")
      .map(tag => tag.trim())
      .filter(Boolean);

    const nodeInput = {
      label: newNodeLabel,
      name: newNodeName,
      description: newNodeLabel === "CONCEPT" ? newNodeDescription : "",
      tags: tagsArray,
      privacy_status: newNodePrivacy,
      status: newNodeStatus,
      properties: {},
    };

    try {
      const createdNodeResponse = await onSubmitNode(nodeInput);
      if (originNode?.id && handleCreateRelationship) {
        const newNode = createdNodeResponse?.createNode?.nodes?.[0];
        if (newNode?.id) {
          const relationshipInput = {
            type: "RELATED_TO",
            source: originNode.id,
            target: newNode.id,
            properties: {},
          };
          await handleCreateRelationship(relationshipInput);
        }
      }
      if (onCreate) onCreate();

      // Reset the form
      setNewNodeName("");
      setNewNodeDescription("");
      setNewNodeTags("");
      setNewNodePrivacy("PRIVATE");
      setNewNodeLabel(defaultLabel);
      setNewNodeStatus("PENDING_VALIDATION");
    } catch (error) {
      console.error("Error creating node:", error);
    }
  };

  return (
    <form onSubmit={handleCreateNodeSubmit} className="bg-gray-800 p-2 rounded space-y-2 text-sm">
      {/* Name row */}
      <div className="flex items-center gap-1">
        <label className="w-16 text-gray-300 font-medium">Name</label>
        <input
          type="text"
          value={newNodeName}
          onChange={(e) => setNewNodeName(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
          required
        />
      </div>

      {/* Label row */}
      <div className="flex items-center gap-1">
        <label className="w-16 text-gray-300 font-medium">Label</label>
        <select
          value={newNodeLabel}
          onChange={(e) => setNewNodeLabel(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="CONCEPT">CONCEPT</option>
          <option value="ARTICLE">ARTICLE</option>
          <option value="COMMENT">COMMENT</option>
        </select>
      </div>

      {/* Description row (only if label is CONCEPT) */}
      {newNodeLabel === "CONCEPT" && (
        <div className="flex items-start gap-1">
          <label className="w-16 text-gray-300 font-medium">Desc</label>
          <textarea
            value={newNodeDescription}
            onChange={(e) => setNewNodeDescription(e.target.value)}
            rows={2}
            className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded resize-none"
          />
        </div>
      )}

      {/* Tags row */}
      <div className="flex items-center gap-1">
        <label className="w-16 text-gray-300 font-medium">Tags</label>
        <input
          type="text"
          value={newNodeTags}
          onChange={(e) => setNewNodeTags(e.target.value)}
          placeholder="comma-separated"
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        />
      </div>

      {/* Privacy */}
      <div className="flex items-center gap-1">
        <label className="w-16 text-gray-300 font-medium">Privacy</label>
        <select
          value={newNodePrivacy}
          onChange={(e) => setNewNodePrivacy(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="PUBLIC">PUBLIC</option>
          <option value="PRIVATE">PRIVATE</option>
          <option value="RESTRICTED">RESTRICTED</option>
        </select>
      </div>

      {/* Status */}
      <div className="flex items-center gap-1">
        <label className="w-16 text-gray-300 font-medium">Status</label>
        <select
          value={newNodeStatus}
          onChange={(e) => setNewNodeStatus(e.target.value)}
          className="flex-1 px-1 py-0.5 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="PENDING_VALIDATION">PENDING_VALIDATION</option>
          <option value="DEACTIVATED">DEACTIVATED</option>
        </select>
      </div>

      {/* Buttons */}
      <div className="mt-2 flex justify-end gap-2">
        <button
          type="button"
          onClick={onCancel}
          className="bg-gray-600 hover:bg-gray-500 text-white px-2 py-1 rounded"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-500 text-white px-2 py-1 rounded"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default NodeCreationForm;