import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

const PrivateRoute = ({ allowedRoles, children }) => {
  const { authToken, user, showLoginModal, setShowLoginModal } = useContext(AuthContext);

  if (!authToken || !user) {
    setShowLoginModal(true); // Show login modal if not authenticated
    return <Navigate to="/" replace />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
