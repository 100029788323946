// src/components/AethericAI/DevGraphNavigator/useHandleNodeDelete.js

import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_NODE } from "../../../../graphql/Graph/graph_mutations";

/**
 * A custom hook that encapsulates the `deleteNode` mutation.
 * Returns a function to delete a node + the mutation results.
 */
const useHandleNodeDelete = () => {
  const [deleteNodeMutation, { data: deleteNodeData, error: deleteNodeError }] =
    useMutation(DELETE_NODE);

  // The callback that triggers deletion
  const handleDeleteNode = useCallback(
    async (nodeId) => {
      try {
        const result = await deleteNodeMutation({
          variables: { id: nodeId },
        });
        return result.data;
      } catch (err) {
        console.error("Error deleting node:", err);
        throw err;
      }
    },
    [deleteNodeMutation]
  );

  return {
    handleDeleteNode,  // the function you call
    deleteNodeData,    // the server response
    deleteNodeError,   // any error
  };
};

export default useHandleNodeDelete;