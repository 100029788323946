// src/components/AethericAI/DevGraphNavigator/useGraphData.js

import { useEffect, useRef, useState } from "react";
import { computeGraphMetrics } from "./graph/computeGraphMetrics";

/**
 * useGraphData
 * - Accepts `initialNode`, `data`, AND `originNodeIdRef`.
 * - Merges newly fetched nodes/links + positions them (batched).
 * - Has a manual `insertNodesAndLinks` if you need it.
 */
export const useGraphData = ({ initialNode, data, originNodeIdRef }) => {
  const [graphData, setGraphData] = useState({
    nodes: [
      {
        id: String(initialNode.id),
        name: initialNode.name,
        x: 400,
        y: 300,
      },
    ],
    links: [],
  });

  // Refs
  const graphDataRef = useRef(graphData);
  const rootNodeRef = useRef(initialNode);
  const uniqueNewNodesRef = useRef([]);
  const uniqueNewLinksRef = useRef([]);

  // We'll keep our existing originNodeRef for the actual node ID used to place new nodes
  const originNodeRef = useRef(null);

  // 1) insertNodesAndLinks (unchanged)
  const insertNodesAndLinks = (newNodes = [], newLinks = [], originNodeId = null) => {
    if (originNodeId) {
      originNodeRef.current = originNodeId;
    } 
    else {
      originNodeRef.current = null;
    }
  
    setGraphData((prevData) => {
      const existingNodes = [...prevData.nodes];
      const existingLinks = [...prevData.links];
  
      const existingNodeIds = new Set(existingNodes.map((n) => n.id));
      const existingLinkIds = new Set(existingLinks.map((l) => l.id));
  
      // Filter unique nodes
      const uniqueNewNodes = newNodes.filter((n) => !existingNodeIds.has(n.id));
  
      // Filter unique links
      const uniqueNewLinks = newLinks.filter(
        (l) =>
          !existingLinks.some(
            (existing) =>
              existing.id === l.id &&
              existing.source === l.source &&
              existing.target === l.target &&
              existing.type === l.type
          )
      );
  
      // Combine nodes
      const updatedNodes = [...existingNodes, ...uniqueNewNodes];
  
      // Validate links (source and target must exist in updatedNodes)
      const validLinks = uniqueNewLinks.filter(
        (link) =>
          updatedNodes.some((node) => node.id === link.source) &&
          updatedNodes.some((node) => node.id === link.target)
      );
  
      // Combine links
      const updatedLinks = [...existingLinks, ...validLinks];
  
      // Position new nodes
      if (originNodeId && uniqueNewNodes.length > 0) {
        const originNode = updatedNodes.find((n) => n.id === originNodeId);
        if (originNode) {
          const radius = 10;
          uniqueNewNodes.forEach((node, i) => {
            const angle = (2 * Math.PI * i) / uniqueNewNodes.length;
            node.x = originNode.x + radius * Math.cos(angle);
            node.y = originNode.y + radius * Math.sin(angle);
          });
        }
      }
  
      return { nodes: updatedNodes, links: updatedLinks };
    });
  };

  // 2) On each `data` update, read originNodeIdRef + do the “batched” merge
  useEffect(() => {
    if (!data || !data.getNodeAndRelationships) return;

    // read the ID from the parent ref
    originNodeRef.current = originNodeIdRef?.current || null;

    const fetchedNodes = data.getNodeAndRelationships.nodes || [];
    const fetchedLinks = data.getNodeAndRelationships.relationships || [];
    const nodeIdMap = {};

    setGraphData((prevData) => {
      // same dedup + transform
      const existingNodes = [...prevData.nodes];
      const existingLinks = [...prevData.links];
      const existingNodeIds = new Set(existingNodes.map((n) => n.id));
      const existingLinkIds = new Set(existingLinks.map((l) => l.id));

      const transformedNodes = fetchedNodes.map((node) => {
        nodeIdMap[node.id] = String(node.id); // Populate the map
        return {
          id: String(node.id), // Use top-level `id`
          name: node.name || "Unnamed Node", // Use top-level `name`
          label: node.label, // Use top-level `label`
          description: node.description || "No description provided", // Use `description`
          tags: node.tags || [], // Use `tags`
          privacyStatus: node.privacy_status || "PRIVATE", // Use `privacy_status`
          status: node.status || "PENDING_VALIDATION", // Use `status`
        };
      });

      const transformedLinks = fetchedLinks.map((rel) => ({
        id: String(rel.id),
        source: nodeIdMap[rel.source],
        target: nodeIdMap[rel.target],
        type: rel.type,
        properties: rel.properties,
      }));

      const newNodes = transformedNodes.filter((n) => !existingNodeIds.has(n.id));
      const newLinks = transformedLinks.filter((l) => !existingLinkIds.has(l.id));

      const updatedNodes = [...existingNodes, ...newNodes];
      const updatedLinks = [...existingLinks, ...newLinks];

      // Position newly added nodes around originNodeRef.current (if valid)
      let originNode = null;
      if (originNodeRef.current) {
        originNode = updatedNodes.find((n) => n.id === originNodeRef.current);
      }

      if (originNode && newNodes.length > 0) {
        const radius = 10;
        newNodes.forEach((node, i) => {
          const angle = (2 * Math.PI * i) / newNodes.length;
          node.x = originNode.x + radius * Math.cos(angle);
          node.y = originNode.y + radius * Math.sin(angle);
        });
      } else if (!originNode && newNodes.length > 0) {
        // fallback
        newNodes.forEach((node) => {
          node.x = 400 + (Math.random() - 0.5) * 100;
          node.y = 300 + (Math.random() - 0.5) * 100;
        });
      }

      return { nodes: updatedNodes, links: updatedLinks };
    });
  }, [data, originNodeIdRef]);

  // Keep graphData in a ref
  useEffect(() => {
    graphDataRef.current = graphData;
  }, [graphData]);

  // 3) Recompute metrics whenever new stuff is added
  useEffect(() => {
    if (uniqueNewNodesRef.current.length > 0 || uniqueNewLinksRef.current.length > 0) {
      const { nodes, links } = graphDataRef.current;
      const rootNode = rootNodeRef.current;

      const { hierarchy, weight } = computeGraphMetrics(nodes, links, rootNode.id);

      const updatedNodes = nodes.map((node) => ({
        ...node,
        properties: {
          ...(node.properties || {}),
          weight: weight[node.id] || 0,
          hierarchy: hierarchy[node.id] === Infinity ? null : hierarchy[node.id],
        },
      }));

      setGraphData((prev) => ({ ...prev, nodes: updatedNodes }));

      uniqueNewNodesRef.current = [];
      uniqueNewLinksRef.current = [];
    }
  }, [graphData]);

  return {
    graphData,
    insertNodesAndLinks,
  };
};