import { useEffect, useRef, useState, useCallback } from "react";

function rebindLinkReferences(nodes, links) {
  return links.map((link) => {
    let sourceObj = link.source;
    let targetObj = link.target;

    if (typeof sourceObj === "string") {
      sourceObj = nodes.find((n) => n.id === link.source);
    }
    if (typeof targetObj === "string") {
      targetObj = nodes.find((n) => n.id === link.target);
    }

    return {
      ...link,
      source: sourceObj,
      target: targetObj,
    };
  });
}

/**
 * useGraphData
 * 
 * Props:
 *   - initialNode  => { id, name, ... }
 *   - data         => from your GraphQL or other source
 *   - originNodeIdRef => a ref for expansions
 *   - user         => current user object (with .id)
 */
export const useGraphData = ({ initialNode, data, originNodeIdRef, user }) => {
  // 1) Derive the localStorage key from the user’s ID, or fallback
  const storageKey = user?.id ? `graphState-${user.id}` : "graphState-guest";
  //const selectedNodesKey = user?.id ? `selectedNodes-${user.id}` : 'selectedNodes-guest';
  
  const [graphData, setGraphData] = useState(() => {
    console.log(`[useGraphData] (init) Checking localStorage for key=${storageKey}...`);
    // 2) Attempt to load from “graphState-<userId>” or “graphState-guest”
    const saved = localStorage.getItem(storageKey);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        console.log(`[useGraphData] (init) Found saved data. Parsed=`, parsed);
        
        if (parsed?.nodes && parsed?.links) {
          console.log(`[useGraphData] Loaded from localStorage key=${storageKey} =>`, parsed);

          // Rebind link references from string => node objects
          const rebindLinks = rebindLinkReferences(parsed.nodes, parsed.links);
          return {
            nodes: parsed.nodes,
            links: rebindLinks,
          };
        }
      } catch (err) {
        console.error(`[useGraphData] error parsing ${storageKey} =>`, err);
      }
    }

    // If we reach here, no stored data found for this user => fallback
    console.log(`[useGraphData] No data for key=${storageKey}, using single-node fallback.`);
    return {
      nodes: [
        {
          id: String(initialNode.id),
          name: initialNode.name,
          x: 400,
          y: 300,
        },
      ],
      links: [],
    };
  });

  const graphDataRef = useRef(graphData);
  const originNodeRef = useRef(null);

  // Clear only *this user’s* localStorage entry
  const resetGraphData = useCallback(() => {
    localStorage.removeItem(storageKey);
    setGraphData({
      nodes: [
        {
          id: String(initialNode.id),
          name: initialNode.name,
          x: 400,
          y: 300,
        },
      ],
      links: [],
    });
    console.log(`[useGraphData] resetGraphData done for key=${storageKey}`);
  }, [initialNode, storageKey]);

  // 3) Insert new nodes/links
  const insertNodesAndLinks = useCallback(
    (newNodes = [], newLinks = [], originNodeId = null) => {
      setGraphData((prevData) => {
        const existingNodes = [...prevData.nodes];
        const existingLinks = [...prevData.links];

        // filter unique
        const existingNodeIds = new Set(existingNodes.map((n) => n.id));
        const uniqueNewNodes = newNodes.filter((n) => !existingNodeIds.has(n.id));

        const newFilteredLinks = newLinks.filter(
          (l) =>
            !existingLinks.some(
              (ex) => ex.id === l.id && ex.source?.id === l.source && ex.target?.id === l.target
            )
        );

        const updatedNodes = [...existingNodes, ...uniqueNewNodes];
        const combinedLinks = [...existingLinks, ...newFilteredLinks];
        const rebindedLinks = rebindLinkReferences(updatedNodes, combinedLinks);

        // optional radial positioning
        if (originNodeId && uniqueNewNodes.length) {
          const originNode = updatedNodes.find((n) => n.id === originNodeId);
          if (originNode) {
            const radius = 10;
            uniqueNewNodes.forEach((node, i) => {
              const angle = (2 * Math.PI * i) / uniqueNewNodes.length;
              node.x = originNode.x + radius * Math.cos(angle);
              node.y = originNode.y + radius * Math.sin(angle);
            });
          }
        }

        return { nodes: updatedNodes, links: rebindedLinks };
      });
    },
    []
  );

  // 4) Update existing node/link data
  const updateNodesAndLinks = useCallback(
    (updatedNodes = [], updatedLinks = []) => {
      setGraphData((prevData) => {
        const nodeMap = Object.fromEntries(prevData.nodes.map((n) => [n.id, n]));
        const linkMap = Object.fromEntries(prevData.links.map((l) => [l.id, l]));

        updatedNodes.forEach((upd) => {
          if (nodeMap[upd.id]) {
            Object.assign(nodeMap[upd.id], upd);
          }
        });

        updatedLinks.forEach((upd) => {
          if (linkMap[upd.id]) {
            Object.assign(linkMap[upd.id], upd);
          }
        });

        const mergedNodes = Object.values(nodeMap);
        const mergedLinks = Object.values(linkMap);

        const rebindedLinks = rebindLinkReferences(mergedNodes, mergedLinks);
        return { nodes: mergedNodes, links: rebindedLinks };
      });
    },
    []
  );

  // 5) Delete
  const deleteNodesAndLinks = useCallback(
    (nodeIdsToDelete = []) => {
      setGraphData((prevData) => {
        const finalNodes = prevData.nodes.filter((n) => !nodeIdsToDelete.includes(n.id));

        const finalLinks = prevData.links.filter((l) => {
          if (!l.source || !l.target) return false;
          return (
            !nodeIdsToDelete.includes(l.source.id) &&
            !nodeIdsToDelete.includes(l.target.id)
          );
        });

        return { nodes: finalNodes, links: finalLinks };
      });
    },
    []
  );

  // 6) If you want to merge data from props/data, do so here
  useEffect(() => {
    if (data?.getNodeAndRelationships) {
      // e.g. insertNodesAndLinks(data.getNodeAndRelationships.nodes, data.getNodeAndRelationships.relationships, originNodeIdRef?.current)
    }
  }, [data]);

  // Keep a ref
  useEffect(() => {
    graphDataRef.current = graphData;
  }, [graphData]);

  // 7) Save to localStorage => “graphState-<userId>”
  useEffect(() => {
    // Convert link endpoints to IDs so it's serializable
    const savedNodes = graphData.nodes.map((n) => ({ ...n }));
    const savedLinks = graphData.links.map((l) => ({
      ...l,
      source: l.source?.id || l.source,
      target: l.target?.id || l.target,
    }));

    const payload = { nodes: savedNodes, links: savedLinks };
    console.log(`[useGraphData] Saving to localStorage key=${storageKey} =>`, payload);

    localStorage.setItem(storageKey, JSON.stringify(payload));
  }, [graphData, storageKey]);

  return {
    graphData,
    insertNodesAndLinks,
    updateNodesAndLinks,
    deleteNodesAndLinks,
    resetGraphData,
  };
};