import { useCallback, useRef, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_NODE_AND_RELATIONSHIPS } from "../../../../graphql/Graph/graph_queries";
import AuthContext from "../../../../contexts/AuthContext";  

const useHandleNodeEvents = ({
  userRole,
  setActionPanelOpen,
  setPanelOpenNode,
  setSelectedNodes,
  selectedNodesRef,
  panelOpenNodeRef,
  actionPanelOpenRef,
  originNodeIdRef,
  setAiMessages,
  setIsAiHeaderOpen,
  // setSelectedNodeIds,
}) => {
  const clickTimeout = useRef(null);

  // 1) Define our lazy query for fetching node + relationships
  const [getNodeAndRelationships, { data: queryData, error: queryError }] =
    useLazyQuery(GET_NODE_AND_RELATIONSHIPS);

  // 2) Our fetch function (same ID logic as before)
  const fetchNodeAndRelationships = useCallback(
    ({ id, name, depth = 1, originNodeId = null }) => {
      // console.log("originNodeId in fetchNodeAndRelationships:", originNodeId);
      originNodeIdRef.current = originNodeId || null;

      const variables = { depth };
      if (id && id.trim() !== "") {
        variables.id = id;
      } else if (name && name.trim() !== "") {
        variables.name = name;
      } else {
        console.error("No valid id or name provided for fetchNodeAndRelationships");
        return;
      }

      try {
        getNodeAndRelationships({ variables });
      } catch (fetchError) {
        console.error("Error calling getNodeAndRelationships:", fetchError);
      }
    },
    [getNodeAndRelationships, originNodeIdRef]
  );

  // 3) handleNodeClick
  const handleNodeClick = useCallback(
    
    (node) => {
      originNodeIdRef.current = node.id;

      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      clickTimeout.current = setTimeout(() => {
        // If this node has no stable ID, handle ephemeral logic (panel toggling)
        if (!node.id) {
          if (
            actionPanelOpenRef.current &&
            panelOpenNodeRef.current?.id === node.id
          ) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          } else {
            setActionPanelOpen(true);
            actionPanelOpenRef.current = true;
            setPanelOpenNode(node);
            panelOpenNodeRef.current = node;
          }
        } else {
          // We have a real node.id -> fetch if not expanded
          // 2) Real node => handle expansions
          const finalId = node.properties?.id || node.id;
          // If the node is not expanded *OR* the user is ADMIN => refetch
          // if (!node.expanded || userRole === 'ADMIN') {
          if (!node.expanded) {
            console.log("userRole in handleNodeClick:", userRole);
            fetchNodeAndRelationships({
              id: finalId,
              depth: 1,
              originNodeId: finalId,
            });
            node.expanded = true;  // Mark it or keep it consistent
          } else {
            console.log("Node is already expanded; ignoring fetch for non-admin user.");
          }

          // If the panel is open, close it
          if (actionPanelOpenRef.current) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          }
        }

        clickTimeout.current = null;
      }, 200);
    },
    [
      setActionPanelOpen,
      setPanelOpenNode,
      fetchNodeAndRelationships,
      actionPanelOpenRef,
      panelOpenNodeRef,
    ]
  );

  // 4) handleNodeDoubleClick
  const handleNodeDoubleClick = useCallback(
    (node) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      if (!node.id) {
        console.debug("Double-clicked node without ID:", node);
        return;
      }

      // Now read the current selection from refs:
      const currentSelected = selectedNodesRef.current;
      const isSelected = currentSelected.some(
        (selectedNode) => selectedNode.id === node.id
      );
      const isPanelOpenForNode =
        actionPanelOpenRef.current &&
        panelOpenNodeRef.current?.id === node.id;

      let newSelected;

      // Decide how to update panelOpenNode/actionPanelOpen and newSelected
      if (isSelected) { //&& isPanelOpenForNode) {
        // close panel and deselect node
        setActionPanelOpen(false);
        actionPanelOpenRef.current = false;
        setPanelOpenNode(null);
        panelOpenNodeRef.current = null;
        newSelected = currentSelected.filter((n) => n.id !== node.id);
      } else if (isSelected && !isPanelOpenForNode) {
        // panel not open for this selected node, so open it
        setActionPanelOpen(true);
        actionPanelOpenRef.current = true;
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = currentSelected;
      } else if (!isSelected) {
        // not selected yet, select and open panel
        setActionPanelOpen(true);
        actionPanelOpenRef.current = true;
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = [...currentSelected, node];
      } else {
        // selected but panel is open for another node -> switch to this node
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = currentSelected;
      }

      // Update selectedNodes once all other state and refs are set
      setSelectedNodes(newSelected);
      // setSelectedNodeIds(newSelected.map((n) => n.id));

      // --------------------------------------------
      // 2) Now also push info to AiHeader
      // --------------------------------------------
      // If you'd like role-specific details:
      // let extraInfo = "";
      // if (userRole === "ADMIN") {
      //   extraInfo = "DEBUG Info => pinnedPositions, or other secret data...";
      // }

      // const nodeText = `
      // **Double-Clicked Node**  
      // Name: ${node.name || "Unnamed"}  
      // ID: ${node.id}  
      // Description: ${node.description || "No description"}  
      // Status: ${node.status || "N/A"}  
      // Privacy: ${node.privacy_status || "N/A"}  
      
      // ${extraInfo}
      // `.trim();

      const nodeName = node.name || "Unnamed";
      // If the node’s `description` is missing or literally "No description", treat it like no value
      const nodeDesc = (!node.description || node.description === "No description")
        ? ""
        : node.description;
      
      let nodeText = nodeName;
      
      // If nodeDesc has actual content, append “: nodeDesc”
      if (nodeDesc) {
        nodeText += `: ${nodeDesc}`;
      }
      
      // If user is admin, append extra lines
      if (userRole === "ADMIN") {
        nodeText += `\nID: ${node.id} Status: ${node.status || "N/A"} Privacy: ${node.privacy_status || "N/A"}`;
      }
      
      // Then push it to the AiHeader
      setAiMessages((prev) => [...prev, nodeText]);
      setIsAiHeaderOpen(true);

    },
    [
      setActionPanelOpen,
      setPanelOpenNode,
      setSelectedNodes,
      // setSelectedNodeIds,
      selectedNodesRef,
      panelOpenNodeRef,
      actionPanelOpenRef,
      setAiMessages,
      setIsAiHeaderOpen,
    ]
  );

  // 5) Return event handlers + queryData + queryError
  return {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  };
};

export default useHandleNodeEvents;