import * as d3 from "d3";
import { wrapText } from "../utils/wrapText";
import { getNodeWeight } from "./utils/getNodeWeight";

/** 
 * A file-level variable to keep track of the currently opened ephemeral group.
 * This persists across re-renders of the `updateNodes` function.
 */
let currentlyOpenGroup = null;

/**
 * reapplyNodeLabels - helper for multi-line node labels
 */
function reapplyNodeLabels(selection, { wrapTextFn, initialNodeId, rootNodeId }) {
  selection.each(function (d) {
    d3.select(this).selectAll("text.node-label").remove();

    // radius logic for label sizing
    const radius = d.id === initialNodeId
      ? 60
      : d.id === rootNodeId
        ? 50
        : 40;

    const maxTextWidth = radius * 2 - 8;
    const { lines, fontSize, lineHeight } = wrapTextFn(
      d.name,
      maxTextWidth,
      "12px sans-serif",
      radius
    );

    const textElement = d3.select(this)
      .append("text")
      .attr("class", "node-label")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "#ffffff")
      .style("font-size", `${fontSize}px`)
      .style("pointer-events", "none"); // so clicks pass through the text

    const totalHeight = lines.length * lineHeight;
    const startY = -totalHeight / 2 + lineHeight / 2;

    lines.forEach((line) => {
      textElement
        .append("tspan")
        .attr("x", 0)
        .attr("y", startY + line.y)
        .text(line.text);
    });
  });
}

/**
 * showEphemeralIcons - Shows ephemeral icons for the given node <g>
 * Hides them on any previously opened group if needed.
 */
function showEphemeralIcons(groupSelection) {
  if (currentlyOpenGroup && currentlyOpenGroup !== groupSelection) {
    // Hide ephemeral icons on the previously open group
    currentlyOpenGroup.select(".node-buttons").style("display", "none");
  }
  // Show the requested group's icons
  groupSelection.select(".node-buttons").style("display", "inline");
  // Mark it as the currently open group
  currentlyOpenGroup = groupSelection;
}

/**
 * hideEphemeralIcons - Hides ephemeral icons from the given <g>, if that group
 * is the one currently open.
 */
function hideEphemeralIcons(groupSelection) {
  groupSelection.select(".node-buttons").style("display", "none");
  if (currentlyOpenGroup === groupSelection) {
    currentlyOpenGroup = null;
  }
}

/**
 * updateNodes - main function that draws/updates node circles,
 * ephemeral button icons, label text, plus drag logic, etc.
 */
export function updateNodes({
  nodesLayer,
  nodesData,
  initialNodeId,
  rootNodeId,
  selectedNodeIds,
  panelOpenNodeId,
  onClick,           // single-click => triggers DB query
  onDoubleClick,     // double-click => do something else
  simulationRef,
  onSetPinnedPosition,
  handleDeleteNode,
  onOpenEditModal,
  onOpenCreateModal,
  originNodeIdRef,
  svgRef,            // so we can attach a "global" click to hide icons
  // ... (handleCreateNode, handleUpdateNode) if needed
}) {
  // 1) Data JOIN
  const node = nodesLayer.selectAll(".node").data(nodesData, (d) => d.id);

  // 2) EXIT
  node.exit().remove();

  // 3) ENTER
  const nodeEnter = node
    .enter()
    .append("g")
    .attr("class", "node")
    .on("click", (event, d) => onClick(d))         // single-click => DB query
    .on("dblclick", (event, d) => onDoubleClick(d))// double-click => your logic
    /**
     * Use pointerdown so that the ephemeral icons remain visible
     * after the user lifts their finger. We do .stopPropagation()
     * so that the "background" global pointerdown won't hide them.
     */
    .on("pointerdown", function(event, d) {
      event.stopPropagation();
      showEphemeralIcons(d3.select(this));
    });

  // Invisible circle for bigger hitbox
  nodeEnter
    .append("circle")
    .attr("class", "invisible-circle")
    .attr("r", (d) => {
      const w = getNodeWeight(d);
      if (d.id === initialNodeId) return 60;
      return d.id === rootNodeId ? 50 : 40 + 30 / w;
    })
    .attr("fill", "transparent")
    .on("click", (event, d) => onClick(d))
    .on("dblclick", (event, d) => onDoubleClick(d));

  // Visible circle
  nodeEnter
    .append("circle")
    .attr("class", "visible-circle")
    .attr("r", (d) =>
      d.id === initialNodeId ? 60 : d.id === rootNodeId ? 50 : 40
    );

  // Child group for ephemeral button icons
  const buttonGroupEnter = nodeEnter
    .append("g")
    .attr("class", "node-buttons")
    .style("display", "none"); // hidden by default

  // The ephemeral icons around each node
  const ephemeralButtons = [
    {
      name: "new",
      icon: "➕",
      angleDeg: -30, // above
      onClick: (event, d) => {
        event.stopPropagation();
        originNodeIdRef.current = d.id;
        onOpenCreateModal?.(d);
      },
    },
    {
      name: "edit",
      icon: "✏",
      angleDeg: 30, // below
      onClick: (event, d) => {
        event.stopPropagation();
        originNodeIdRef.current = d.id;
        onOpenEditModal?.(d);
      },
    },
    {
      name: "remove",
      icon: "🗑",
      angleDeg: 90, // right side
      onClick: (event, d) => {
        event.stopPropagation();
        if (window.confirm(`Delete node '${d.name}'?`)) {
          handleDeleteNode?.(d.id);
        }
      },
    },
  ];

  ephemeralButtons.forEach((btn) => {
    buttonGroupEnter
      .append("text")
      .attr("class", "ephemeral-icon")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", 14)
      .attr("fill", "#ddd")
      .attr("cursor", "pointer")
      .attr("x", function (d) {
        const radius =
          d.id === initialNodeId
            ? 60
            : d.id === rootNodeId
            ? 50
            : 40;
        const offset = radius + 12;
        const rad = (btn.angleDeg * Math.PI) / 180;
        return offset * Math.cos(rad);
      })
      .attr("y", function (d) {
        const radius =
          d.id === initialNodeId
            ? 60
            : d.id === rootNodeId
            ? 50
            : 40;
        const offset = radius + 12;
        const rad = (btn.angleDeg * Math.PI) / 180;
        return offset * Math.sin(rad);
      })
      .text(btn.icon)
      .on("click", btn.onClick);
  });

  // MERGE
  const nodeMerged = nodeEnter.merge(node);

  // Remove old drag
  nodeMerged.on(".drag", null);

  // Draggable for non-root
  const draggableNodes = nodeMerged.filter(
    (d) => d.id !== rootNodeId && d.id !== initialNodeId
  );
  draggableNodes.call(
    d3.drag()
      .on("start", (event, d) => {
        if (!simulationRef.current) return;
        if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      })
      .on("drag", (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      })
      .on("end", (event, d) => {
        if (!simulationRef.current) return;
        if (!event.active) simulationRef.current.alphaTarget(0.01);
        // If node is not selected => unpin
        if (!selectedNodeIds.includes(d.id)) {
          d.fx = null;
          d.fy = null;
          onSetPinnedPosition?.(d.id, null);
        } else {
          // Keep pinned if user has it selected
          onSetPinnedPosition?.(d.id, { fx: d.fx, fy: d.fy });
        }
      })
  );

  // Style the visible circle
  nodeMerged
    .select(".visible-circle")
    .attr("fill", (d) => {
      switch (d.label) {
        case "USER":         return "#9b59b6"; // purple
        case "ARTICLE":      return "#2ecc71"; // green
        case "COMMENT":      return "#95a5a6"; // gray
        case "USER_REQUEST": return "#f1c40f"; // yellow
        case "CONCEPT":
        default:             return "black";
      }
    })
    .attr("stroke", (d) => {
      if (selectedNodeIds.includes(d.id)) {
        return "yellow";
      }
      if (d.id === initialNodeId) return "white";
      if (d.id === rootNodeId)   return "#f39c12";

      switch (d.status) {
        case "ACTIVE":            return "white";
        case "PENDING_VALIDATION":return "orange";
        case "DEACTIVATED":       return "gray";
        default:                  return "white";
      }
    })
    .attr("stroke-width", (d) =>
      panelOpenNodeId === d.id
        ? 4
        : (d.id === initialNodeId || d.id === rootNodeId)
          ? 3
          : 2
    )
    .style("stroke-dasharray", (d) => {
      switch (d.privacyStatus) {
        case "PUBLIC":     return null;  // solid
        case "PRIVATE":    return "2,2"; // dotted
        case "RESTRICTED": return "6,3"; // dashed
        default:           return null;
      }
    });

  // Re-run text labeling
  reapplyNodeLabels(nodeMerged, {
    wrapTextFn: wrapText,
    initialNodeId,
    rootNodeId,
  });

  /**
   * Finally, attach a pointerdown on <svg> so if the user taps
   * outside the currently open group, it hides the ephemeral icons.
   */
  if (svgRef?.current) {
    // We'll re-attach this only once per mount. 
    // (If your code re-calls updateNodes multiple times, 
    // you might want to do a check or .once.)
    d3.select(svgRef.current).on("pointerdown.hideIcons", (event) => {
      // If no ephemeral icons are open, do nothing
      if (!currentlyOpenGroup) return;

      // Did the pointerdown happen inside that group?
      const isInsideGroup =
        event.composedPath &&
        event.composedPath().includes(currentlyOpenGroup.node());
      if (!isInsideGroup) {
        // Hide ephemeral icons
        hideEphemeralIcons(currentlyOpenGroup);
      }
    });
  }
}