// src/components/AethericAI/DevGraphNavigator/buildSystemContext.js

/**
 * buildSystemContext
 * Extracts minimal fields from the selected nodes (id, label, name, etc.)
 * and returns a string for your AI's systemContent or context.
 * 
 * @param {Array} selectedNodes - array of objects containing at least:
 *   { id, label, name, description, tags, ... }
 * @returns {String} - a readable JSON string used for AI context
 */
export function buildSystemContext(selectedNodes) {
    // Step 1: Filter out only the fields you want for AI
    const minimalNodes = selectedNodes.map((node) => {
      return {
        id: node.id,
        label: node.label,
        name: node.name,
        description: node.description,
        tags: node.tags,
      };
    });
  
    // Step 2: Convert to JSON with indentation
    const jsonString = JSON.stringify(minimalNodes, null, 2);
  
    // Step 3: Build a final message
    // You can keep it simple or add instructions here, e.g.:
    return (
      "The user has selected these nodes:\n" +
      jsonString +
      "\n\nPlease respond with ephemeral Graph suggestions, plus a userOutput text."
    );
  }