// src/components/AethericAI/DevGraphNavigator/NewGraphNavigator.js

import React, { useState, useEffect, useRef } from "react";
import { InitialRootProvider } from "../../../contexts/InitialRootContext";
import { useGraphData } from "./useGraphData";
import { useD3Graph } from "./useD3Graph";
import { ActionPanel } from "./ActionPanel";
import useHandleNodeEvents from "./handleNodeEvents";
import useHandleNodeCreation from "./handleNodeCreation";
import useHandleRelationshipCreation from "./handleRelationshipCreation";
import useHandleNodeUpdate from "./useHandleNodeUpdate";


const NewGraphNavigator = () => {
  // 1) Selected nodes
  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedNodesRef = useRef([]);
  useEffect(() => {
    selectedNodesRef.current = selectedNodes;
  }, [selectedNodes]);

  // 2) Action panel
  const [actionPanelOpen, setActionPanelOpen] = useState(false);
  const actionPanelOpenRef = useRef(false);
  useEffect(() => {
    actionPanelOpenRef.current = actionPanelOpen;
  }, [actionPanelOpen]);

  // 3) Root node
  const [rootNode, setRootNode] = useState({
    id: "a0de03b3-b027-43e5-a411-0ccfe7820613",
    name: "AethericAI",
  });
  const initialRootNodeRef = useRef(rootNode);

  // 4) Panel open node
  const [panelOpenNode, setPanelOpenNode] = useState(null);
  const panelOpenNodeRef = useRef(null);

  // For storing last originNodeId
  const originNodeIdRef = useRef(null);

  // 5) Node events (click/dblclick -> queries)
  const {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  } = useHandleNodeEvents({
    setActionPanelOpen,
    setPanelOpenNode,
    setSelectedNodes,
    selectedNodesRef,
    panelOpenNodeRef,
    actionPanelOpenRef,
    originNodeIdRef,
  });

  // 6) Graph data from queries
  const { graphData, insertNodesAndLinks } = useGraphData({
    initialNode: rootNode,
    data: queryData,
    originNodeIdRef,
  });

  // 7) D3 Graph
  const { svgRef, zoomToNode } = useD3Graph({
    graphData,
    initialNode: initialRootNodeRef.current,
    rootNode,
    selectedNodes,
    panelOpenNodeId: panelOpenNode ? panelOpenNode.id : null,
    onNodeClick: handleNodeClick,
    onNodeDoubleClick: handleNodeDoubleClick,
  });

  // 8) Node creation hook
  const {
    handleCreateNode,
    createNodeData,
    createNodeError,
  } = useHandleNodeCreation();

  const {
     handleCreateRelationship,
     relData,
     relError,
   } = useHandleRelationshipCreation();

  const {
    handleUpdateNode,
    updateNodeData,
    updateNodeError,
  } = useHandleNodeUpdate();

  /**
   * 9) When new node data arrives, merge into graph + open panel if desired
   */
  useEffect(() => {
    if (!createNodeData?.createNode) return;

    console.log("New node data arrived:", createNodeData);

    const { nodes: newNodes = [], relationships: newRelationships = [] } =
      createNodeData.createNode;

    console.log("New Nodes:", newNodes);
    console.log("New Relationships:", newRelationships);

    // Convert or compute final IDs
    const processedNodes = newNodes.map((n) => ({
      id: String(n.id), // Top-level `id`
      name: n.name || "Unnamed Node",
      label: n.label,
      description: n.description || "No description provided",
      tags: n.tags || [],
      privacyStatus: n.privacy_status || "public",
      status: n.status || "active",
    }));
    // Insert them
    insertNodesAndLinks(processedNodes, newRelationships);

    // Optionally open panel, zoom, etc.
    if (processedNodes.length > 0) {
      const createdNode = processedNodes[0];
      setPanelOpenNode(createdNode);
      setActionPanelOpen(true);
      zoomToNode(createdNode.id, 1.01);
    }
  }, [createNodeData]); // not depending on insertNodesAndLinks => to avoid infinite loop

  /**
   * 10) When new relationship data arrives, merge it if you want to see it in the graph
   */
  useEffect(() => {
    if (!relData?.createRelationship) return;

    console.log("New relationship data arrived:", relData);

    // const { relationships: newRels = [] } = relData.createRelationship;
    const newRel = relData.createRelationship;

    // If there's no new node here, we can pass an empty array for nodes
    insertNodesAndLinks([], [newRel]);
  }, [relData]); // same note about dependencies

  useEffect(() => {
      if (!updateNodeData?.updateNode) return;
    
      console.log("Updated node data arrived:", updateNodeData);
      const { nodes: updatedNodes = [], relationships: updatedRels = [] } =
        updateNodeData.updateNode;
    
      // Convert or compute final IDs
      const processedNodes = updatedNodes.map((n) => ({
        id: String(n.id),
        name: n.name || "Unnamed Node",
        label: n.label,
        description: n.description || "No description provided",
        tags: n.tags || [],
        privacyStatus: n.privacy_status || "public",
        status: n.status || "active",
      }));
    
      insertNodesAndLinks(processedNodes, updatedRels);

      // Optionally open the panel for that node, zoom in, etc.
      if (processedNodes.length > 0) {
        const updatedNode = processedNodes[0];
        setPanelOpenNode(updatedNode);
        setActionPanelOpen(true);
        zoomToNode(updatedNode.id, 1.01);
      }
    }, [updateNodeData]);

  // Debug logs
  useEffect(() => {
    // console.log("actionPanelOpen changed:", actionPanelOpen);
  }, [actionPanelOpen]);

  useEffect(() => {
    if (panelOpenNode) {
      zoomToNode(panelOpenNode.id, 1.01);
    }
  }, [panelOpenNode, zoomToNode]);

  useEffect(() => {
    if (queryError) {
      console.error("Query error:", queryError);
    }
    if (createNodeError) {
      console.error("Create node error:", createNodeError);
    }
    if (relError) {
      console.error("Create relationship error:", relError);
    }
    if (updateNodeError) {
      console.error("Update node error:", updateNodeError);
    }
  }, [queryError, createNodeError, relError, updateNodeError]);

  const handleSetRoot = (node) => {
    if (node?.id) {
      setRootNode({ ...node, id: String(node.id) });
    } else if (node) {
      setRootNode({ id: "", name: node.name || "Unnamed Node" });
    } else {
      setRootNode({ id: "", name: "Unknown Node" });
    }
  };

  return (
    <InitialRootProvider initialRoot={initialRootNodeRef.current}>
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>
        {actionPanelOpen && (
          <ActionPanel
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            onSetRoot={handleSetRoot}
            rootNode={rootNode}
            panelOpenNode={panelOpenNode}
            setPanelOpenNode={setPanelOpenNode}
            actionPanelOpen={actionPanelOpen}
            setActionPanelOpen={setActionPanelOpen}
            handleCreateNode={handleCreateNode}
            handleCreateRelationship={handleCreateRelationship}
            handleUpdateNode={handleUpdateNode}
          />
        )}
        <svg ref={svgRef} style={{ width: "100%", height: "100%" }} />
      </div>
    </InitialRootProvider>
  );
};

export default NewGraphNavigator;