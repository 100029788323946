import { useCallback, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_NODE_AND_RELATIONSHIPS } from "../../../graphql/Graph/graph_queries";

const useHandleNodeEvents = ({
  setActionPanelOpen,
  setPanelOpenNode,
  setSelectedNodes,
  selectedNodesRef,
  panelOpenNodeRef,
  actionPanelOpenRef,
  originNodeIdRef,  // we’ll use this to store the last originNodeId
}) => {
  const clickTimeout = useRef(null);

  // 1) Define our lazy query for fetching node + relationships
  const [getNodeAndRelationships, { data: queryData, error: queryError }] =
    useLazyQuery(GET_NODE_AND_RELATIONSHIPS);

  // 2) Our fetch function (same ID logic as before)
  const fetchNodeAndRelationships = useCallback(
    ({ id, name, depth = 1, originNodeId = null }) => {
      // console.log("originNodeId in fetchNodeAndRelationships:", originNodeId);
      originNodeIdRef.current = originNodeId || null;

      const variables = { depth };
      if (id && id.trim() !== "") {
        variables.id = id;
      } else if (name && name.trim() !== "") {
        variables.name = name;
      } else {
        console.error("No valid id or name provided for fetchNodeAndRelationships");
        return;
      }

      try {
        getNodeAndRelationships({ variables });
      } catch (fetchError) {
        console.error("Error calling getNodeAndRelationships:", fetchError);
      }
    },
    [getNodeAndRelationships, originNodeIdRef]
  );

  // 3) handleNodeClick
  const handleNodeClick = useCallback(
    (node) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      clickTimeout.current = setTimeout(() => {
        // If this node has no stable ID, handle ephemeral logic (panel toggling)
        if (!node.id) {
          if (
            actionPanelOpenRef.current &&
            panelOpenNodeRef.current?.id === node.id
          ) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          } else {
            setActionPanelOpen(true);
            actionPanelOpenRef.current = true;
            setPanelOpenNode(node);
            panelOpenNodeRef.current = node;
          }
        } else {
          // We have a real node.id -> fetch if not expanded
          const finalId = node.properties?.id || node.id;

          if (!node.expanded) {
            fetchNodeAndRelationships({
              id: finalId,
              depth: 1,
              originNodeId: finalId,
            });
            node.expanded = true;
          }

          // If the panel is open, close it
          if (actionPanelOpenRef.current) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          }
        }

        clickTimeout.current = null;
      }, 200);
    },
    [
      setActionPanelOpen,
      setPanelOpenNode,
      fetchNodeAndRelationships,
      actionPanelOpenRef,
      panelOpenNodeRef,
    ]
  );

  // 4) handleNodeDoubleClick
  const handleNodeDoubleClick = useCallback(
    (node) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      if (!node.id) {
        console.debug("Double-clicked node without ID:", node);
        return;
      }

      // Now read the current selection from refs:
      const currentSelected = selectedNodesRef.current;
      const isSelected = currentSelected.some(
        (selectedNode) => selectedNode.id === node.id
      );
      const isPanelOpenForNode =
        actionPanelOpenRef.current &&
        panelOpenNodeRef.current?.id === node.id;

      let newSelected;

      // Decide how to update panelOpenNode/actionPanelOpen and newSelected
      if (isSelected && isPanelOpenForNode) {
        // close panel and deselect node
        setActionPanelOpen(false);
        actionPanelOpenRef.current = false;
        setPanelOpenNode(null);
        panelOpenNodeRef.current = null;
        newSelected = currentSelected.filter((n) => n.id !== node.id);
      } else if (isSelected && !isPanelOpenForNode) {
        // panel not open for this selected node, so open it
        setActionPanelOpen(true);
        actionPanelOpenRef.current = true;
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = currentSelected;
      } else if (!isSelected) {
        // not selected yet, select and open panel
        setActionPanelOpen(true);
        actionPanelOpenRef.current = true;
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = [...currentSelected, node];
      } else {
        // selected but panel is open for another node -> switch to this node
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = currentSelected;
      }

      // Update selectedNodes once all other state and refs are set
      setSelectedNodes(newSelected);
    },
    [
      setActionPanelOpen,
      setPanelOpenNode,
      setSelectedNodes,
      selectedNodesRef,
      panelOpenNodeRef,
      actionPanelOpenRef,
    ]
  );

  // 5) Return event handlers + queryData + queryError
  return {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  };
};

export default useHandleNodeEvents;