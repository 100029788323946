// src/components/AethericAI/DevGraphNavigator/useHandleNodeUpdate.js

import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_NODE } from "../../../graphql/Graph/graph_mutations";

const useHandleNodeUpdate = () => {
  // 1) `UPDATE_NODE` mutation
  const [updateNodeMutation, { data: updateNodeData, error: updateNodeError }] =
    useMutation(UPDATE_NODE);

  /**
   * 2) The callback that updates the node in the backend
   */
  const handleUpdateNode = useCallback(
    async (nodeId, nodeInput) => {
      try {
        // Typically we separate `nodeId` from the NodeInput
        // so the mutation knows "which node" to update + "what fields" to apply
        const result = await updateNodeMutation({
          variables: { id: nodeId, input: nodeInput },
        });
        return result.data;
      } catch (err) {
        console.error("Error updating node:", err);
      }
    },
    [updateNodeMutation]
  );

  return {
    handleUpdateNode,
    updateNodeData,
    updateNodeError,
  };
};

export default useHandleNodeUpdate;