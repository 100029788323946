// src/components/LandingPage/LandingPage.js

import React from 'react';
import logo from '../../assets/images/AethericAI.png';

const LandingPage = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow min-h-screen">
      <img
        src={logo}
        alt="AethericAI Logo"
        className="w-32 h-auto md:w-48 lg:w-56"
      />
    </div>
  );
};

export default LandingPage;
