// src/components/Home.js

import React, { useContext } from 'react';
import AuthContext from '../../contexts/AuthContext';
import logo from '../../assets/images/AethericAI.png';
import LoginModal from '../Auth/LoginModal';

const Home = () => {
  const { showLoginModal } = useContext(AuthContext);

  return (
    <div className="flex justify-center items-center bg-black flex-grow min-h-screen">
      <img
        src={logo}
        alt="AethericAI Logo"
        className="w-32 h-auto md:w-48 lg:w-56"
      />
    </div>
  );
};

export default Home;
