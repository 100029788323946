// src/components/AethericAI/DevGraphNavigator/handleNodeCreation.js
import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_NODE } from "../../../../graphql/Graph/graph_mutations";

/**
 * A custom hook that encapsulates ONLY the CREATE_NODE mutation
 */
const useHandleNodeCreation = () => {
  // 1) CREATE_NODE mutation
  const [createNodeMutation, { data: createNodeData, error: createNodeError }] =
    useMutation(CREATE_NODE);

  /**
   * 2) The callback that just creates the node
   */
  const handleCreateNode = useCallback(
    async (nodeInput) => {
      try {
        const result = await createNodeMutation({ variables: { input: nodeInput } });
        return result.data; // so you can get createdNode in the caller
      } catch (err) {
        console.error("Error creating node:", err);
      }
    },
    [createNodeMutation]
  );

  // 3) Return only node-related data
  return {
    handleCreateNode,
    createNodeData,   // holds { createNode: { nodes, relationships } }
    createNodeError,
  };
};

export default useHandleNodeCreation;