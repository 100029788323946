// src/App.js

import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext from "./contexts/AuthContext";
import { LoginModal, PrivateRoute, Unauthorized } from "./components/Auth";
import Home from "./components/Home/Home";
import AdminPage from "./components/Admin/AdminPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from "./components/Layout/Header";
import UserAdmin from "./components/Admin/UserAdmin";
import AethericAI from "./components/AethericAI/AethericAI";
import LandingPage from "./components/LandingPage/LandingPage";
import DevAethericAI from "./components/AethericAI/dev_AethericAI";

function App() {
  const { logout } = useContext(AuthContext);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleCloseModal = () => {
    setShowLoginModal(false);
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Router>
      {/* 
        Use a flex container that spans the full screen.
        We'll make the header sticky so it stays visible if 
        the user scrolls the main content. 
      */}
      <div className="flex flex-col min-h-screen bg-black text-white">
        
        {/* 1) Sticky Header */}
        <header className="sticky top-0 z-50 bg-black">
          <Header onLoginClick={handleLoginClick} onLogoutClick={handleLogoutClick} />
        </header>

        {/* 2) Main Content Area */}
        {/* 
          We give this area flex-grow so it can expand to fill the rest 
          of the viewport, plus an overflow-y-auto so only this area 
          scrolls and the header remains visible.
        */}
        <main className="flex-grow overflow-y-auto flex">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route
              path="/landingpage"
              element={<LandingPage />}
            />

            <Route
              path="/home"
              element={
                <PrivateRoute allowedRoles={["ADMIN", "EDITOR", "PRIVILEGED_USER", "USER"]}>
                  <Home />
                </PrivateRoute>
              }
            />

            <Route
              path="/aethericai"
              element={
                <PrivateRoute allowedRoles={["ADMIN", "EDITOR", "PRIVILEGED_USER", "USER"]}>
                  <AethericAI />
                </PrivateRoute>
              }
            />

            <Route
              path="/dev_aethericai"
              element={
                <PrivateRoute allowedRoles={["ADMIN", "EDITOR"]}>
                  <DevAethericAI />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute allowedRoles={["USER", "ADMIN", "EDITOR", "PRIVILEGED_USER"]}>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <PrivateRoute allowedRoles={["ADMIN"]}>
                  <AdminPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute allowedRoles={["ADMIN"]}>
                  <UserAdmin />
                </PrivateRoute>
              }
            />

            {/* 
              Example if you want ConceptAdmin re-added:
              <Route
                path="/admin/concepts"
                element={
                  <PrivateRoute allowedRoles={["ADMIN"]}>
                    <ConceptAdmin />
                  </PrivateRoute>
                }
              />
            */}
            
            <Route component={Unauthorized} />
          </Routes>
        </main>

        {/* 3) Login Modal */}
        {showLoginModal && <LoginModal onClose={handleCloseModal} />}
      </div>
    </Router>
  );
}

export default App;