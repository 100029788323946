// src/components/BurgerMenu.js

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

// Import icons from Heroicons (ensure you have installed @heroicons/react)
import { ChevronDownIcon } from '@heroicons/react/24/solid';

const menuItems = [
  {
    name: 'Home',
    path: '/home',
    roles: ['USER', 'ADMIN','EDITOR','PRIVILEGED_USER'],
  },
  {
    name: 'AethericAI',
    path: '/aethericai',
    roles: ['USER', 'ADMIN','EDITOR','PRIVILEGED_USER'],
  },
  {
    name: 'Dev AethericAI',
    path: '/dev_aethericai',
    roles: ['ADMIN','EDITOR'],
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    roles: ['USER', 'ADMIN','EDITOR','PRIVILEGED_USER'],
  },
  {
    name: 'Admin',
    path: '/admin',
    roles: ['ADMIN'],
    children: [
      {
        name: 'User Admin',
        path: '/admin/users',
        roles: ['ADMIN'],
      },
      // {
      //   name : 'Concept Admin',
      //   path : '/admin/concepts',
      //   roles : ['ADMIN'],
      // }
      // Add more submenu items here if needed
    ],
  },
];

const BurgerMenu = ({ onClose }) => {
  const { user } = useContext(AuthContext);
  const [openSubmenus, setOpenSubmenus] = useState({});

  const hasAccess = (itemRoles) => {
    if (!itemRoles) return true;
    if (!user) return false;
    return itemRoles.includes(user.role);
  };

  const toggleSubmenu = (e, menuName) => {
    e.stopPropagation(); // Prevent the click from bubbling up
    e.preventDefault(); // Prevent default action
    setOpenSubmenus((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
  };

  return (
    <div className="fixed top-16 left-0 w-64 bg-black text-white flex flex-col p-4 z-[9999] shadow-lg overflow-visible">
      {menuItems.map((item) =>
        hasAccess(item.roles) ? (
          <div key={item.name} className="w-full">
            {item.children ? (
              <div>
                <div className="flex items-center justify-between w-full">
                  {/* Link to navigate to item's path */}
                  <Link
                    to={item.path}
                    className="flex-grow text-left hover:bg-gray-700 rounded px-2 py-2"
                    onClick={onClose}
                  >
                    {item.name}
                  </Link>
                  {/* Button to toggle submenu */}
                  <button
                    onClick={(e) => toggleSubmenu(e, item.name)}
                    className="flex-shrink-0 focus:outline-none px-2"
                    aria-haspopup="true"
                    aria-expanded={openSubmenus[item.name] ? 'true' : 'false'}
                  >
                    <ChevronDownIcon
                      className={`h-5 w-5 transform transition-transform duration-200 ${
                        openSubmenus[item.name] ? 'rotate-180' : 'rotate-0'
                      }`}
                    />
                  </button>
                </div>
                {/* Render submenu items if the submenu is open */}
                {openSubmenus[item.name] && (
                  <div className="ml-4 mt-1">
                    {item.children.map((subItem) =>
                      hasAccess(subItem.roles) ? (
                        <Link
                          key={subItem.name}
                          to={subItem.path}
                          className="block py-2 px-2 hover:bg-gray-700 rounded"
                          onClick={onClose}
                        >
                          {subItem.name}
                        </Link>
                      ) : null
                    )}
                  </div>
                )}
              </div>
            ) : (
              <Link
                to={item.path}
                className="block py-2 px-2 hover:bg-gray-700 rounded"
                onClick={onClose}
              >
                {item.name}
              </Link>
            )}
          </div>
        ) : null
      )}
    </div>
  );
};

export default BurgerMenu;
