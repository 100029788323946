// src/components/AethericAI/DevGraphNavigator/useD3Graph.js
import { useRef, useEffect, useCallback, useState } from "react";
import * as d3 from "d3";
import { initializeSVG } from "../graph/initializeSVG";
import { updateLinks } from "../graph/updateLinks";
import { updateNodes } from "../graph/updateNodes";
import { initializeOrUpdateSimulation } from "../graph/initializeSimulation";
import { ticked } from "../graph/ticked";
import { zoomToNode } from "../graph/zoomToNode";

export const useD3Graph = ({
  graphData,
  width = 800,
  height = 600,
  initialNode,
  rootNode,
  selectedNodes = [],
  panelOpenNodeId,
  onNodeClick,
  onNodeDoubleClick,
  pinnedPositions,
  setPinnedPositions,
  handleCreateNode,
  handleUpdateNode,
  handleDeleteNode,
  onOpenEditModal,
  onOpenCreateModal,
  originNodeIdRef,
}) => {
  // console.warn("In useD3Graph");
  // console.log("graphData", graphData);

  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const simulationRef = useRef(null);

  const previousRootIdRef = useRef(rootNode.id);
  const [initialNodeId] = useState(initialNode?.id);

  const tickedCallback = useCallback(() => {
    ticked(svgRef);
  }, [svgRef]);

  // Define a small helper to update pinnedPositions
  const handleSetPinnedPosition = useCallback((nodeId, pinned) => {
    // pinned is either { fx, fy } or null to remove
    setPinnedPositions(prev => {
      const next = { ...prev };
      if (!pinned) {
        delete next[nodeId];
      } else {
        next[nodeId] = pinned;
      }
      return next;
    });
  }, [setPinnedPositions]);

  useEffect(() => {
    if (
      !graphData ||
      !graphData.nodes ||
      graphData.nodes.length === 0
    ) {
      console.log("Graph data not yet ready -> skipping D3 initialization");
      return;
    }

    const svg = d3.select(svgRef.current);

    // Measure actual dimensions
    let measuredWidth = width;
    let measuredHeight = height;
    if (svgRef.current) {
      const rect = svgRef.current.getBoundingClientRect();
      measuredWidth = rect.width;
      measuredHeight = rect.height;
    }

    // Initialize SVG elements once
    if (!zoomRef.current) {
      initializeSVG(zoomRef, svg);
    }

    const svgGroup = svg.select(".graph-group");
    const linksLayer = svgGroup.select(".links-layer");
    const nodesLayer = svgGroup.select(".nodes-layer");

    // 2) Pre‐assign fx/fy for pinned nodes before we start the simulation
    //    e.g. if pinnedPositions[nodeId] is defined, apply it to that node
    graphData.nodes.forEach(d => {
      const pinned = pinnedPositions?.[d.id];
      if (pinned?.fx != null && pinned?.fy != null) {
        d.fx = pinned.fx;
        d.fy = pinned.fy;
      } else {
        // If it’s not pinned => clear them out
        d.fx = null;
        d.fy = null;
      }
    });

    // Update links and nodes
    updateLinks(linksLayer, graphData.links);

    updateNodes({
      nodesLayer,
      nodesData: graphData.nodes,
      initialNodeId,
      rootNodeId: rootNode.id,
      selectedNodeIds: selectedNodes.map((node) => node.id),
      panelOpenNodeId,
      onClick: onNodeClick,
      onDoubleClick: onNodeDoubleClick,
      simulationRef,
      onSetPinnedPosition: handleSetPinnedPosition,

      // Forward these so ephemeral buttons can use them:
      handleCreateNode,
      handleUpdateNode,
      handleDeleteNode,
      onOpenEditModal,
      onOpenCreateModal,

      originNodeIdRef
    });

    // Init or update the force simulation
    initializeOrUpdateSimulation({
      simulationRef,
      nodes: graphData.nodes,
      links: graphData.links,
      rootId: rootNode.id,
      initialNodeId,
      previousRootIdRef,
      ticked: tickedCallback,
      measuredWidth,
      measuredHeight,
    });

    // Cleanup on unmount
    return () => {
      if (simulationRef.current) {
        simulationRef.current.stop();
      }
    };
  }, [
    graphData,
    rootNode.id,
    selectedNodes,
    panelOpenNodeId,
    onNodeClick,
    onNodeDoubleClick,
    pinnedPositions,         // <— if pinnedPositions changes, re-run
    handleSetPinnedPosition, // <— if callback changes, re-run
    width,
    height,
    tickedCallback,
    initialNodeId,
  ]);

  // Wrap your imported zoomToNode utility in a callback
  const zoomToNodeCallback = useCallback(
    (nodeId, scaleFactor) => {
      if (!graphData?.nodes?.length) return;
      zoomToNode({ svgRef, graphData, nodeId, width, height, scaleFactor });
    },
    [graphData, width, height]
  );

  return { svgRef, zoomToNode: zoomToNodeCallback };
};