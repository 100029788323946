// src/components/AethericAI/DevGraphNavigator/components/AiHeader.js

import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const AiHeader = ({ messages = [], open = false, onClose }) => {
  if (!open || messages.length === 0) return null;

  const handleCloseClick = () => {
    console.log("[AiHeader] X clicked => calling onClose()");
    onClose?.();
  };

  return (
    <div
      className="
        w-full
        bg-black
        text-white
        p-3
        relative  /* The parent is relative so the button can be absolutely positioned */
        z-80
      "
    >
      {/* Close (X) button, pinned in top-right, always visible */}
      <button
        onClick={handleCloseClick}
        className="
          absolute
          top-2
          right-2
          text-gray-400
          hover:text-white
          focus:outline-none
        "
        aria-label="Close AI Message"
      >
        <XMarkIcon className="h-4 w-4" />
      </button>

      {/* Scrollable content */}
      <div className="max-h-20 overflow-y-auto pr-6 text-sm leading-relaxed whitespace-pre-wrap">
                {messages.map((msg, index) => (
            <div key={index} className="mb-2">
              {msg}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AiHeader;