// src/components/AethericAI/DevGraphNavigator/NewGraphNavigator.js
import SparkMD5 from 'spark-md5';
import React, { useState, useEffect, useRef, useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";  
import { InitialRootProvider } from "../../../contexts/InitialRootContext";
import { useGraphData } from "./hook/useGraphData"; // <-- Now includes updateNodesAndLinks
import { useD3Graph } from "./hook/useD3Graph";
import { ActionPanel } from "./components/ActionPanel";
import useHandleNodeEvents from "./hook/handleNodeEvents";
import useHandleNodeCreation from "./hook/handleNodeCreation";
import useHandleRelationshipCreation from "./hook/handleRelationshipCreation";
import useHandleNodeUpdate from "./hook/useHandleNodeUpdate";
import useHandleNodeDelete from "./hook/useHandleNodeDelete";
import AiFooter from "./components/AiFooter";
import AiHeader from "./components/AiHeader";
import NodeCreationForm from "./components/NodeCreationForm";
import UpdateNodeForm from "./components/UpdateNodeForm";



const NewGraphNavigator = () => {
  const { user } = useContext(AuthContext); 


  // We'll build a localStorage key for the selectedNodes
  const selectedNodesKey = user?.id
  ? `selectedNodes-${user.id}`
  : null; // or undefined

  const pinnedPositionsKey = user?.id ? `pinnedPositions-${user.id}` : null;

  const canOpenPanel = user && (user.role === "ADMIN" || user.role === "EDITOR");

  // 1) Selected nodes
  const [selectedNodes, setSelectedNodes] = useState([]);
  // const [selectedNodeIds, setSelectedNodeIds] = useState([]);

  const [pinnedPositions, setPinnedPositions] = useState(() => {
      // On mount, attempt to load pinned positions
      if (!pinnedPositionsKey) return {};
      const saved = localStorage.getItem(pinnedPositionsKey);
      if (!saved) return {};
      try {
        return JSON.parse(saved);
      } catch (err) {
        console.error("Error parsing pinnedPositions from localStorage:", err);
        return {};
      }
  });

    // 3) Whenever pinnedPositions changes, save to localStorage
  useEffect(() => {
      if (!pinnedPositionsKey) return;
      console.log(`[NewGraphNavigator] Saving pinnedPositions to localStorage (key: ${pinnedPositionsKey})...`);
      localStorage.setItem(pinnedPositionsKey, JSON.stringify(pinnedPositions));
    }, [pinnedPositions, pinnedPositionsKey]);

  // In the useEffect that LOADS from localStorage on mount:
  useEffect(() => {
    console.log(`[NewGraphNavigator] Attempting to load selectedNodes from localStorage (key: ${selectedNodesKey})...`);
    

    // If user is not yet loaded, skip
    if (!selectedNodesKey) {
      console.warn("[NewGraphNavigator] user?.id not found; skipping localStorage load for now.");
      return;
    }
    console.log(`[NewGraphNavigator] Attempting to load selectedNodes from localStorage (key: ${selectedNodesKey})...`);
    

    const saved = localStorage.getItem(selectedNodesKey);
    console.warn(`[NewGraphNavigator] localStorage.getItem(${selectedNodesKey}) =>`, saved);
    
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        console.log(`[NewGraphNavigator] Successfully parsed localStorage:`, parsed);
        if (Array.isArray(parsed)) {
          setSelectedNodes(parsed);
        } else {
          console.warn(`[NewGraphNavigator] The parsed localStorage data is not an array.`, parsed);
        }
      } catch (err) {
        console.error(`[NewGraphNavigator] Error parsing localStorage for selectedNodesKey=${selectedNodesKey}:`, err);
      }
    } else {
      console.log(`[NewGraphNavigator] No saved selection found for key=${selectedNodesKey}.`);
    }
  }, [selectedNodesKey]);

  const [didLoadFromStorage, setDidLoadFromStorage] = useState(false);

  useEffect(() => {
    // LOAD effect
    const saved = localStorage.getItem(selectedNodesKey);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        if (Array.isArray(parsed)) {
          setSelectedNodes(parsed);
        }
      } catch (err) {
        console.error("Error parsing localStorage:", err);
      }
    }
    setDidLoadFromStorage(true);
  }, [selectedNodesKey]);
  
  useEffect(() => {
    // SAVE effect
    if (!didLoadFromStorage) {
      console.log("[NewGraphNavigator] Skipping save; not done loading from localStorage yet.");
      return;
    }
    console.log("[NewGraphNavigator] Now saving selectedNodes =>", selectedNodes);
    localStorage.setItem(selectedNodesKey, JSON.stringify(selectedNodes));
  }, [didLoadFromStorage, selectedNodes, selectedNodesKey]);

  const selectedNodesRef = useRef([]);

  useEffect(() => {
    selectedNodesRef.current = selectedNodes;
  }, [selectedNodes]);

  // 2) Action panel
  const [actionPanelOpen, setActionPanelOpen] = useState(false);
  const actionPanelOpenRef = useRef(false);
  useEffect(() => {
    actionPanelOpenRef.current = actionPanelOpen;
  }, [actionPanelOpen]);

  // 3) Root node
  const [rootNode, setRootNode] = useState({
    id: "a0de03b3-b027-43e5-a411-0ccfe7820613",
    name: "AethericAI",
  });
  const initialRootNodeRef = useRef(rootNode);

  // 4) Panel open node
  const [panelOpenNode, setPanelOpenNode] = useState(null);
  const panelOpenNodeRef = useRef(null);

  // const [didProcessCreateNode, setDidProcessCreateNode] = useState(false);
  // const [haveProcessedUpdate, setHaveProcessedUpdate] = useState(false);
  const [lastUpdateNodeData, setLastUpdateNodeData] = useState(null);


  // Optional: track the userOutput locally
  const [aiUserOutput, setAiUserOutput] = useState("");

  const [aiMessages, setAiMessages] = useState([]);



  // A boolean controlling the AiHeader’s visibility 
  const [isAiHeaderOpen, setIsAiHeaderOpen] = useState(false);

  // For storing last originNodeId
  const originNodeIdRef = useRef(null);

  const lastUserOutputRef = useRef("");

  // to be able to display the modal by itself 
  const [editNodeModalOpen, setEditNodeModalOpen] = useState(false);
  const [nodeToEdit, setNodeToEdit] = useState(null);

  const [createNodeModalOpen, setCreateNodeModalOpen] = useState(false);
  const [originNodeForCreation, setOriginNodeForCreation] = useState(null);

  // Wrap them in callbacks:
  const onOpenEditModal = (node) => {
    setNodeToEdit(node);
    setEditNodeModalOpen(true);
  };

  const onOpenCreateModal = (originNode) => {
    setOriginNodeForCreation(originNode);
    setCreateNodeModalOpen(true);
  };

  // 5) Node events (click/dblclick -> queries)
  const {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  } = useHandleNodeEvents({
    userRole: user?.role,
    setActionPanelOpen,
    setPanelOpenNode,
    setSelectedNodes,
    selectedNodesRef,
    panelOpenNodeRef,
    actionPanelOpenRef,
    originNodeIdRef,
    // setSelectedNodeIds,
    setAiMessages,
    setIsAiHeaderOpen,
  });

  // 6) Graph data from queries
  //    Now also destructure `updateNodesAndLinks` from the return
  const { 
    graphData,
    insertNodesAndLinks,
    updateNodesAndLinks,
    deleteNodesAndLinks,
    resetGraphData,  
  } = useGraphData({
    initialNode: rootNode,
    data: queryData,
    originNodeIdRef,
    user,
  });

  useEffect(() => {
    if (!graphData?.nodes) return;
    // For each node, if pinnedPositions[node.id] is defined and the node is actually selected => set node.fx, node.fy
    graphData.nodes.forEach((node) => {
      if (selectedNodes.some((sel) => sel.id === node.id)) {
        const pinned = pinnedPositions[node.id];
        if (pinned) {
          node.fx = pinned.fx;
          node.fy = pinned.fy;
        }
      } else {
        // If it's not selected, clear fx/fy so it can move
        node.fx = null;
        node.fy = null;
      }
    });
  }, [graphData, selectedNodes, pinnedPositions]);

  // 8) Node creation hook
  const {
    handleCreateNode,
    createNodeData,
    createNodeError,
  } = useHandleNodeCreation();

  // 9) Node update hook
  const {
    handleUpdateNode,
    updateNodeData,
    updateNodeError,
  } = useHandleNodeUpdate();

  // 10) Node delete hook
  const {
    handleDeleteNode,
    deleteNodeData,
    deleteNodeError,
  } = useHandleNodeDelete();

  // 7) D3 Graph
  const { svgRef, zoomToNode } = useD3Graph({
    graphData,
    initialNode: initialRootNodeRef.current,
    rootNode,
    selectedNodes,
    panelOpenNodeId: panelOpenNode ? panelOpenNode.id : null,
    onNodeClick: handleNodeClick,
    onNodeDoubleClick: handleNodeDoubleClick,
    pinnedPositions,
    setPinnedPositions,

    // If you want ephemeral buttons to do creation/updating:
    handleCreateNode,
    handleUpdateNode,
    handleDeleteNode,

    // Provide the modals logic:
    onOpenEditModal,      // calls setNodeToEdit, etc.
    onOpenCreateModal,    // calls setOriginNodeForCreation, etc.

    originNodeIdRef,
  });

  const {
    handleCreateRelationship,
    relData,
    relError,
  } = useHandleRelationshipCreation();



  // For ephemeral insertion from AI
  const handleInsertEphemeralGraph = (aiNodes, aiRels) => {
    // You can call insertNodesAndLinks with no origin ID, or e.g. originNodeIdRef.current
    insertNodesAndLinks(aiNodes, aiRels, null);
  };

  // This is called after the AI returns a structure
  const handleAiResponse = (aiData) => {
    const { userOutput } = aiData || {};
    if (!userOutput) return;
  
    // Add a quick check:
    setAiMessages((prev) => {
      if (prev.length > 0 && prev[prev.length - 1] === userOutput) {
        console.log("[handleAiResponse] Skipping duplicate AI response");
        return prev; // no change => no re-render
      }
      console.log("[handleAiResponse] Pushing new AI message =>", userOutput);
      return [...prev, userOutput];
    });
  
    setIsAiHeaderOpen(true);
  };

  // In NewGraphNavigator.js
  useEffect(() => {
    if (!queryData?.getNodeAndRelationships) return;
  
    // Shallow‐clone the array items
    const fetchedNodes = queryData.getNodeAndRelationships.nodes.map((n) => ({ ...n }));
    const fetchedLinks = queryData.getNodeAndRelationships.relationships.map((r) => ({ ...r }));
  
    // Insert them radially around originNodeIdRef.current
    insertNodesAndLinks(fetchedNodes, fetchedLinks, originNodeIdRef.current);
  }, [queryData, insertNodesAndLinks]);

  /**
   * When new node data arrives, do an insert
   */
  
  const [lastCreateKey, setLastCreateKey] = useState(null);

  useEffect(() => {
    // 1) If there's no createNodeData, skip
    if (!createNodeData?.createNode) return;
  
    // 2) Pull out the newly created nodes from the response
    const { nodes: newNodes = [], relationships: newRelationships = [] } =
      createNodeData.createNode;
  
    if (!newNodes.length) return;
  
    // Build a “key” that uniquely represents these newly created nodes,
    // e.g. by sorting their IDs.
    const nodeIdsKey = newNodes
      .map((n) => n.id)
      .sort() // put them in a consistent order
      .join("-");
  
    // If it’s the same key as last time, skip
    if (nodeIdsKey === lastCreateKey) {
      console.log("Skipping: we've already processed these newly-created nodes =>", nodeIdsKey);
      return;
    }
  
    // Otherwise, this is new => process
    setLastCreateKey(nodeIdsKey);
  
    console.log("New node data arrived:", createNodeData);
    console.log("New Nodes:", newNodes);
    console.log("New Relationships:", newRelationships);
  
    // 2) Convert your node shape
    const processedNodes = newNodes.map((n) => ({
      id: String(n.id),
      name: n.name || "Unnamed Node",
      label: n.label,
      description: n.description || "No description provided",
      tags: n.tags || [],
      privacyStatus: n.privacy_status || "PRIVATE",
      status: n.status || "PENDING_VALIDATION",
    }));
  
    // 3) Insert
    insertNodesAndLinks(processedNodes, newRelationships, originNodeIdRef.current);
  
    // 4) If you want to open the panel or zoom
    if (processedNodes.length > 0) {
      const createdNode = processedNodes[0];
      setPanelOpenNode(createdNode);
      setActionPanelOpen(true);
      zoomToNode(createdNode.id, 1.01);
    }
  }, [
    createNodeData,
    lastCreateKey,
    insertNodesAndLinks,
    setPanelOpenNode,
    setActionPanelOpen,
    zoomToNode,
    originNodeIdRef
  ]);

  /**
   * When new relationship data arrives, do an insert
   */
  useEffect(() => {
    if (!relData?.createRelationship) return;

    console.log("New relationship data arrived:", relData);

    const newRel = relData.createRelationship;
    insertNodesAndLinks([], [newRel]);
  }, [relData, insertNodesAndLinks]);

  /**
   * When updated node data arrives, use updateNodesAndLinks
   */

  const [lastUpdateKey, setLastUpdateKey] = useState(null);

  useEffect(() => {
    if (!updateNodeData?.updateNode) return;
  
    console.log("Updated node data arrived:", updateNodeData);
    const { nodes: updatedNodes = [], relationships: updatedRels = [] } =
      updateNodeData.updateNode;
  
    if (!updatedNodes.length) return;
  
    // Build a single string of each node's relevant fields
    // (id, name, description, status, privacyStatus, tags).
    // Then join them so we get one big string for ALL updated nodes.
    const nodeHashString = updatedNodes
      .map((n) => {
        const sortedTags = (n.tags || []).sort().join(",");
        return [
          n.id,
          n.name || "",
          n.description || "",
          n.status || "",
          n.privacy_status || "",
          sortedTags
        ].join(":");
      })
      .sort()         // So the order of nodes doesn't matter
      .join("|");     // A separator between nodes
  
    // Now pass that to SparkMD5 (or any other hashing library)
    const nodeHash = SparkMD5.hash(nodeHashString);
  
    // If it's the same as last time, skip
    if (nodeHash === lastUpdateKey) {
      console.log("Skipping update: nodeHash is unchanged =>", nodeHash);
      return;
    }
  
    // Otherwise, store it
    setLastUpdateKey(nodeHash);
  
    // ============= Now do your usual merging logic =============
    // Convert node fields to local shape
    const processedNodes = updatedNodes.map((n) => ({
      id: String(n.id),
      name: n.name,
      label: n.label,
      description: n.description,
      tags: n.tags || [],
      privacy_status : n.privacy_status || "PRIVATE",
      status: n.status || "PENDING_VALIDATION",
    }));
  
    updateNodesAndLinks(processedNodes, updatedRels);
  
    // Optionally open the panel or zoom in
    if (processedNodes.length > 0) {
      const updatedNode = processedNodes[0];
      setPanelOpenNode(updatedNode);
      setActionPanelOpen(true);
      zoomToNode(updatedNode.id, 1.01);
    }
  }, [
    updateNodeData,
    lastUpdateKey,
    setLastUpdateKey,
    updateNodesAndLinks,
    setPanelOpenNode,
    setActionPanelOpen,
    zoomToNode,
  ]);

  /**
   * When node deletion data arrives
   */
  useEffect(() => {
    if (!deleteNodeData?.deleteNode) return;
  
    const { success, message, deletedId } = deleteNodeData.deleteNode;
  
    if (success && deletedId) {
      console.log("Node deletion succeeded:", message);
      // remove from local graph
      deleteNodesAndLinks([deletedId]);
  
      // close the panel if that node was open
      if (panelOpenNode?.id === deletedId) {
        setPanelOpenNode(null);
        setActionPanelOpen(false);
      }
    } else {
      console.warn("Deletion refused:", message);
    }
  }, [deleteNodeData, deleteNodesAndLinks, panelOpenNode, setPanelOpenNode, setActionPanelOpen]);

  // Debug logs
  useEffect(() => {
    if (panelOpenNode) {
      zoomToNode(panelOpenNode.id, 1.01);
    }
  }, [panelOpenNode, zoomToNode]);

  useEffect(() => {
    if (queryError) console.error("Query error:", queryError);
    if (createNodeError) console.error("Create node error:", createNodeError);
    if (relError) console.error("Create relationship error:", relError);
    if (updateNodeError) console.error("Update node error:", updateNodeError);
  }, [queryError, createNodeError, relError, updateNodeError]);

  const handleSetRoot = (node) => {
    if (node?.id) {
      setRootNode({ ...node, id: String(node.id) });
    } else if (node) {
      setRootNode({ id: "", name: node.name || "Unnamed Node" });
    } else {
      setRootNode({ id: "", name: "Unknown Node" });
    }
  };

  return (
    <InitialRootProvider initialRoot={initialRootNodeRef.current}>
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {/* AiHeader at top */}
      <div className="fixed top-16 left-0 right-0 z-30">
        <AiHeader
          messages={aiMessages}
          open={isAiHeaderOpen}
          onClose={() => setIsAiHeaderOpen(false)}
        />
      </div>

      {user?.role === 'ADMIN' && (
      <button
        onClick={() => {
          // Same logic: clears local graph state + localStorage
          resetGraphData();
          setSelectedNodes([]);
          localStorage.removeItem(selectedNodesKey);
          if (pinnedPositionsKey) localStorage.removeItem(pinnedPositionsKey);
          setPinnedPositions({});
        }}
        title="Clear locally saved graph data"
        className="
          fixed
          bottom-14      /* positions it just above the AiFooter (which is bottom-0) */
          right-4
          z-40
          flex
          items-center
          justify-center
          w-8
          h-8
          bg-gray-800
          hover:bg-gray-700
          text-white
          rounded-full
          cursor-pointer
        "
      >
        {/* A small “refresh” SVG icon; from Heroicons or similar */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 4v6h6M20 20v-6h-6M9 14l3 3 3-3M15 10l-3-3-3 3"
          />
        </svg>
      </button>
    )}

          {/* 
            Show a small button “>” to re-open, but only IF:
            1) the AiHeader is not open
            2) we actually have userOutput to display
          */}
          {!isAiHeaderOpen && aiMessages.length > 0 && (
            <button
              onClick={() => {
                console.log("[NewGraphNavigator] open AiHeader => setIsAiHeaderOpen(true)");
                setIsAiHeaderOpen(true);
              }}
              className="
                fixed      
                top-16
                left-0
                right
                bg-black
                text-white
                p-3
                overflow-y-auto
                max-h-20
                z-40
              "
              title="Open AI Message"
            >
              &gt;
            </button>
          )}
  
        {/* Wrap ActionPanel in a container with higher z-index */}
        {/* {actionPanelOpen && canOpenPanel && (
          <div className="absolute z-40">
            <ActionPanel
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
              onSetRoot={handleSetRoot}
              rootNode={rootNode}
              panelOpenNode={panelOpenNode}
              setPanelOpenNode={setPanelOpenNode}
              actionPanelOpen={actionPanelOpen}
              setActionPanelOpen={setActionPanelOpen}
              handleCreateNode={handleCreateNode}
              handleCreateRelationship={handleCreateRelationship}
              handleUpdateNode={handleUpdateNode}
              handleDeleteNode={handleDeleteNode}
            />
          </div>
        )} */}
  
        <svg ref={svgRef} style={{ width: "100%", height: "100%" }} />

        {editNodeModalOpen && nodeToEdit && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex justify-center items-center">
          <div className="bg-gray-800 p-4 rounded">
            <UpdateNodeForm
              currentNode={nodeToEdit}
              onCancel={() => {
                setEditNodeModalOpen(false);
                setNodeToEdit(null);
              }}
              onSubmitUpdate={handleUpdateNode}
            />
          </div>
        </div>
      )}

      {createNodeModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex justify-center items-center">
          <div className="bg-gray-800 p-4 rounded">
            <NodeCreationForm
              originNode={originNodeForCreation}
              onCancel={() => setCreateNodeModalOpen(false)}
              onCreate={() => setCreateNodeModalOpen(false)}
              onSubmitNode={handleCreateNode}
              handleCreateRelationship={handleCreateRelationship}
            />
          </div>
        </div>
      )}
  
        {/* AI Footer pinned to bottom */}
        <div className="fixed bottom-0 left-0 right-0 z-10">
          <AiFooter
            selectedNodes={selectedNodes}
            onAiResponse={handleAiResponse}
            handleSelectNode={(node) => {
              // e.g. if you want to open the panel for that node:
              // setPanelOpenNode(node);
              // setActionPanelOpen(true);
            }}
            handleRemoveNode={(node) => {
              // e.g. remove from selection:
              setSelectedNodes((prev) => prev.filter((n) => n.id !== node.id));

              // If that node was open in the panel, close it:
              if (panelOpenNode?.id === node.id) {
                setPanelOpenNode(null);
                setActionPanelOpen(false);
              }
            }}
          />
        </div>
      </div>

      <div
      id="tooltip"
      className="
        pointer-events-none 
        absolute 
        z-50 
        bg-black 
        text-white 
        px-3 py-2 
        rounded 
        shadow 
        opacity-0 
        transition-opacity 
        duration-300
      "
      style={{ top: 0, left: 0 }}
      />
    </InitialRootProvider>
  );
};

export default NewGraphNavigator;