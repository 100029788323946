/**
 * Computes both hierarchy and weight for each node in the graph.
 *
 * @param {Array} nodes - Array of node objects. Each node should have an `id`.
 * @param {Array} links - Array of link objects. Each link should have `source` and `target` IDs.
 * @param {string|number} rootId - The ID of the root node.
 * @returns {Object} - An object containing `hierarchy` and `weight` for each node.
 */

export const computeGraphMetrics = (nodes, links, rootId) => {
  // console.log('Compute Graph Metrics: Nodes:', nodes);
  // console.log('Compute Graph Metrics: Links:', links);
  // console.log('Compute Graph Metrics: Root ID:', rootId);

  const adjacency = createAdjacencyList(nodes, links);
  // console.log('Final Adjacency List:', adjacency);

  const hierarchy = computeHierarchy(adjacency, rootId);
  // console.log('Hierarchy:', hierarchy);

  const rawWeight = computeWeight(adjacency); // This returns raw degree counts
  const weight = normalizeWeight(rawWeight);  // Normalize the weights
  // console.log('Normalized Weight:', weight);

  return { hierarchy, weight };
};

/**
 * Creates an adjacency list from nodes and links.
 *
 * @param {Array} nodes - Array of node objects with `id`.
 * @param {Array} links - Array of link objects with `source` and `target`.
 * @returns {Map} - The adjacency list.
 */
export const createAdjacencyList = (nodes, links) => {
  const adjacency = new Map();

  // Initialize adjacency map with all node IDs
  nodes.forEach(node => adjacency.set(String(node.id), []));

  links.forEach(link => {
    const sourceId = typeof link.source === 'object' ? String(link.source.id) : String(link.source);
    const targetId = typeof link.target === 'object' ? String(link.target.id) : String(link.target);

    if (adjacency.has(sourceId)) {
      const sourceNeighbors = adjacency.get(sourceId);
      if (!sourceNeighbors.includes(targetId)) {
        sourceNeighbors.push(targetId);
      }
    } else {
      adjacency.set(sourceId, [targetId]);
    }

    // If the graph is undirected, you could uncomment this section to add the reverse link:
    /*
    if (adjacency.has(targetId)) {
      const targetNeighbors = adjacency.get(targetId);
      if (!targetNeighbors.includes(sourceId)) {
        targetNeighbors.push(sourceId);
      }
    } else {
      adjacency.set(targetId, [sourceId]);
    }
    */
  });

  return adjacency;
};

/**
 * Computes the hierarchy of nodes in a graph using BFS.
 *
 * @param {Map} adjacency - The adjacency list of the graph.
 * @param {string|number} rootId - The ID of the root node.
 * @returns {Object} - An object with node IDs as keys and their hierarchy levels as values.
 */
export const computeHierarchy = (adjacency, rootId) => {
  const hierarchy = {};
  adjacency.forEach((_, node) => {
    hierarchy[node] = Infinity;
  });

  if (!hierarchy.hasOwnProperty(rootId)) {
    console.error(`Root node ${rootId} not found in nodes.`);
    return hierarchy;
  }

  hierarchy[rootId] = 0;
  const queue = [rootId];

  while (queue.length > 0) {
    const current = queue.shift();
    const currentDist = hierarchy[current];

    adjacency.get(current).forEach(neighbor => {
      if (hierarchy[neighbor] === Infinity) {
        hierarchy[neighbor] = currentDist + 1;
        queue.push(neighbor);
      }
    });
  }

  return hierarchy;
};

/**
 * Computes the raw weight (degree) of each node in the graph.
 *
 * @param {Map} adjacency - The adjacency list of the graph.
 * @returns {Object} - An object with node IDs as keys and their raw weights (degrees) as values.
 */
export const computeWeight = (adjacency) => {
  const weight = {};
  adjacency.forEach((neighbors, node) => {
    // console.log('Node:', node);
    // console.log('Neighbors:', neighbors);
    // console.log('Neighbors Length:', neighbors.length);
    weight[node] = neighbors.length;
  });
  return weight;
};

/**
 * Normalizes the weights so that the maximum weight is scaled to 1.
 *
 * @param {Object} rawWeight - Object with node IDs as keys and raw (unscaled) weights as values.
 * @returns {Object} - An object with node IDs as keys and normalized weights (0 to 1) as values.
 */
export const normalizeWeight = (rawWeight) => {
  const values = Object.values(rawWeight);
  if (values.length === 0) return {};

  const maxWeight = Math.max(...values);
  if (maxWeight === 0) {
    // All nodes have 0 weight, return them all as 0
    return Object.keys(rawWeight).reduce((acc, node) => {
      acc[node] = 0;
      return acc;
    }, {});
  }

  const normalized = {};
  for (const node in rawWeight) {
    normalized[node] = rawWeight[node] / maxWeight;
  }
  return normalized;
};
