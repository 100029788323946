// src/components/AethericAI/DevGraphNavigator/NodeCreationForm.js

import React, { useState } from 'react';

const NodeCreationForm = ({
  originNode,
  onCancel,
  onCreate,
  onSubmitNode,
  defaultLabel = "CONCEPT"
}) => {
  const [newNodeLabel, setNewNodeLabel] = useState(defaultLabel);
  const [newNodeName, setNewNodeName] = useState("");
  const [newNodeDescription, setNewNodeDescription] = useState("");
  const [newNodePrivacy, setNewNodePrivacy] = useState("PRIVATE");
  const [newNodeTags, setNewNodeTags] = useState("");
  const [newNodeStatus, setNewNodeStatus] = useState("PENDING_VALIDATION");

  const handleCreateNodeSubmit = async (e) => {
    e.preventDefault();

    // Convert tags to array
    const tagsArray = newNodeTags
      .split(",")
      .map((tag) => tag.trim())
      .filter(Boolean);

    const nodeInput = {
      label: newNodeLabel,
      name: newNodeName,
      description: newNodeLabel === "CONCEPT" ? newNodeDescription : "",
      tags: tagsArray,
      privacy_status: newNodePrivacy,
      status: newNodeStatus,
      properties: {},
    };

    try {
      // onSubmitNode is typically handleCreateNode in the parent
      const createdNodeResponse = await onSubmitNode(nodeInput);

      // If you need the returned node(s), you can do so here:
      // const newNode = createdNodeResponse?.createNode?.nodes?.[0];
      // console.log("New node from server:", newNode);

      // If there's an onCreate callback, call it
      if (onCreate) {
        onCreate();
      }

      // Reset form
      setNewNodeName("");
      setNewNodeDescription("");
      setNewNodeTags("");
      setNewNodePrivacy("PRIVATE");
      setNewNodeLabel(defaultLabel);
      setNewNodeStatus("PENDING_VALIDATION");
    } catch (error) {
      console.error("Error creating node:", error);
    }
  };

  return (
    <form onSubmit={handleCreateNodeSubmit} className="bg-gray-800 p-4 rounded space-y-4">
      {/* Name */}
      <div>
        <label className="block text-sm font-medium mb-1">Name:</label>
        <input
          type="text"
          value={newNodeName}
          onChange={(e) => setNewNodeName(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
          required
        />
      </div>

      {/* Description (only if label is CONCEPT) */}
      {newNodeLabel === "CONCEPT" && (
        <div>
          <label className="block text-sm font-medium mb-1">Description:</label>
          <textarea
            value={newNodeDescription}
            onChange={(e) => setNewNodeDescription(e.target.value)}
            className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
          />
        </div>
      )}

      {/* Tags */}
      <div>
        <label className="block text-sm font-medium mb-1">Tags (comma-separated):</label>
        <input
          type="text"
          value={newNodeTags}
          onChange={(e) => setNewNodeTags(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        />
      </div>

      {/* Privacy */}
      <div>
        <label className="block text-sm font-medium mb-1">Privacy Status:</label>
        <select
          value={newNodePrivacy}
          onChange={(e) => setNewNodePrivacy(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="PUBLIC">PUBLIC</option>
          <option value="PRIVATE">PRIVATE</option>
          <option value="RESTRICTED">RESTRICTED</option>
        </select>
      </div>

      {/* Status */}
      <div>
        <label className="block text-sm font-medium mb-1">Status:</label>
        <select
          value={newNodeStatus}
          onChange={(e) => setNewNodeStatus(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="PENDING_VALIDATION">PENDING_VALIDATION</option>
          <option value="DEACTIVATED">DEACTIVATED</option>
        </select>
      </div>

      {/* Label */}
      <div>
        <label className="block text-sm font-medium mb-1">Label:</label>
        <select
          value={newNodeLabel}
          onChange={(e) => setNewNodeLabel(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="CONCEPT">CONCEPT</option>
          <option value="USER">USER</option>
        </select>
      </div>

      {/* Buttons */}
      <div className="mt-4 flex justify-between">
        <button
          type="button"
          className="bg-gray-700 hover:bg-gray-600 px-3 py-1 rounded"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" className="bg-blue-700 hover:bg-blue-600 px-3 py-1 rounded">
          Save
        </button>
      </div>
    </form>
  );
};

export default NodeCreationForm;