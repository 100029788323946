// src/components/AethericAI/DevGraphNavigator/UpdateNodeForm.js

import React, { useState, useEffect } from "react";

const UpdateNodeForm = ({ currentNode, onCancel, onSubmitUpdate }) => {
  // Let’s store local state from the currentNode
  const [nodeName, setNodeName] = useState(currentNode?.name || "");
  const [nodeDescription, setNodeDescription] = useState(currentNode?.description || "");
  const [nodeTags, setNodeTags] = useState(currentNode?.tags?.join(", ") || "");
  const [nodePrivacy, setNodePrivacy] = useState(currentNode?.privacy_status || "PRIVATE");
  const [nodeStatus, setNodeStatus] = useState(currentNode?.status || "ACTIVE");

  // If `currentNode` changes, re-init the form
  useEffect(() => {
    setNodeName(currentNode?.name || "");
    setNodeDescription(currentNode?.description || "");
    setNodeTags((currentNode?.tags || []).join(", "));
    setNodePrivacy(currentNode?.privacy_status || "PRIVATE");
    setNodeStatus(currentNode?.status || "ACTIVE");
  }, [currentNode]);

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!currentNode?.id) {
      console.error("No currentNode or ID, cannot update.");
      return;
    }
    // Convert tags
    const tagsArray = nodeTags.split(",").map((t) => t.trim()).filter(Boolean);

    const nodeInput = {
      // We can pass label if you want to let user update that too
      name: nodeName,
      description: nodeDescription,
      tags: tagsArray,
      privacy_status: nodePrivacy,
      status: nodeStatus,
      // We can also handle "properties" if you want
      properties: {},
    };

    try {
      // We'll call handleUpdateNode with the node's ID + the new input
      await onSubmitUpdate(currentNode.id, nodeInput);
      // Possibly close the form
      if (onCancel) onCancel();
    } catch (error) {
      console.error("Error updating node:", error);
    }
  };

  return (
    <form onSubmit={handleUpdateSubmit} className="bg-gray-800 p-4 rounded space-y-4">
      <div>
        <label className="block text-sm font-medium mb-1">Name:</label>
        <input
          type="text"
          value={nodeName}
          onChange={(e) => setNodeName(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Description:</label>
        <textarea
          value={nodeDescription}
          onChange={(e) => setNodeDescription(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Tags (comma separated):</label>
        <input
          type="text"
          value={nodeTags}
          onChange={(e) => setNodeTags(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        />
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Privacy Status:</label>
        <select
          value={nodePrivacy}
          onChange={(e) => setNodePrivacy(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="PUBLIC">PUBLIC</option>
          <option value="PRIVATE">PRIVATE</option>
          <option value="RESTRICTED">RESTRICTED</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium mb-1">Status:</label>
        <select
          value={nodeStatus}
          onChange={(e) => setNodeStatus(e.target.value)}
          className="w-full p-1 bg-gray-900 border border-gray-700 rounded"
        >
          <option value="ACTIVE">ACTIVE</option>
          <option value="PENDING_VALIDATION">PENDING_VALIDATION</option>
          <option value="DEACTIVATED">DEACTIVATED</option>
        </select>
      </div>

      <div className="mt-4 flex justify-between">
        <button
          type="button"
          className="bg-gray-700 hover:bg-gray-600 px-3 py-1 rounded"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type="submit" className="bg-blue-700 hover:bg-blue-600 px-3 py-1 rounded">
          Update
        </button>
      </div>
    </form>
  );
};

export default UpdateNodeForm;