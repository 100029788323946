// src/graphql/Graph/graph_queries.js
import { gql } from '@apollo/client';

export const GET_NODE_AND_RELATIONSHIPS = gql`
  query GetNodeAndRelationships($id: ID, $name: String, $depth: Int = 1) {
    getNodeAndRelationships(id: $id, name: $name, depth: $depth) {
      nodes {
        id
        label
        name
        status
        description
        tags
        privacy_status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_GRAPH = gql`
  query GetGraph {
    getGraph {
      nodes {
        id
        label
        name
        status
        description
        tags
        privacy_status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

/** 
 * NEW: AI_REQUEST
 * 
 *  - Pass `systemContent` and (optionally) `userContent` 
 *  - The server returns an AiOutput object which includes 
 *    (1) ephemeral graph data, 
 *    (2) userOutput string, 
 *    (3) systemOutput string.
*/
export const AI_REQUEST = gql`
  query AiRequest($systemContent: String!, $userContent: String) {
    aiRequest(systemContent: $systemContent, userContent: $userContent) {
      graph {
        nodes {
          id
          label
          name
          status
          description
          tags
          privacy_status
          properties
        }
        relationships {
          id
          type
          source
          target
          properties
        }
      }
      userOutput
      systemOutput
    }
  }
`;