// src/graphql/Graph/graph_mutations.js
import { gql } from '@apollo/client';


export const CREATE_NODE = gql`
  mutation CreateNode($input: NodeInput!) {
    createNode(input: $input) {
      nodes {
        id
        label
        name
        description
        tags
        privacy_status
        status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_RELATIONSHIP = gql`
  mutation CreateRelationship($input: RelationshipInput!) {
    createRelationship(input: $input) {
      id
      type
      source
      target
      properties
      createdAt
    }
  }
`;

export const UPDATE_NODE = gql`
  mutation UpdateNode($id: ID!, $input: NodeInput!) {
    updateNode(id: $id, input: $input) {
      nodes {
        id
        label
        name
        description
        tags
        privacy_status
        status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_NODE = gql`
  mutation DeleteNode($id: ID!) {
    deleteNode(id: $id) {
      success
      message
      deletedId
    }
  }
`;